<template>
  <a
    target="_blank"
    :href="doc.dst_path"
    @mouseover="hoverDocument(doc)"
    @mouseleave="leaveDocument(doc)"
    class="cursor-pointer relative"
  >
    <div
      :class="{
        'shadow-md bg-gradient-to-br from-gray-300 to-gray-100': doc.isHover,
      }"
      class="flex flex-row shadow-md rounded-lg bg-gray-200 cursor-pointer"
    >
      <div
        :class="{
          'bg-gradient-to-br from-gray-600 to-gray-300 opacity-90': doc.isHover,
        }"
        class="flex-shrink flex items-center bg-gradient-to-br from-gray-400 to-gray-300 align-middle p-0.5 rounded-l-lg"
      >
        <svg
          :class="{
            'text-gray-800': doc.isHover,
          }"
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 md:h-14 w-10 md:w-14 m-2 md:m-4 text-gray-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      </div>
      <div
        class="flex-grow p-2 flex flex-col items-start"
      >
        <p class="text-md md:text-lg">{{ doc.name }}</p>
        <p class="text-xs md:text-sm text-gray-500">
          {{ doc.description }}
        </p>
        <p class="md:hidden text-sm text-gray-500 underline">
          Scarica subito
        </p>

        <div
          v-show="doc.isHover"
          class="hidden md:flex flex-row-reverse w-full h-full items-end text-yellow-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 relative"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="text-sm text-right text-yellow-500">
            Scarica subito
          </span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "GenericDocumentButton",
  props: ["doc"],
  methods: {
    hoverDocument(doc) {
      doc.isHover = true;
      return;
    },
    leaveDocument(doc) {
      doc.isHover = false;
      return;
    },
  },
};
</script>
