<template>
  <div class="max-w-7xl mx-auto pb-5 sm:pb-10 md:pb-20 lg:mt-40 lg:mb-16 ">
    <div class="mx-4">
      <div class="grid grid-cols-12">
        <div class="col-span-12">
          <h2
            data-aos="fade-up"
            data-aos-duration="400"
            class="text-yellow-500 text-base font-semibold tracking-wide uppercase mt-10"
          >
            I nostri partner
          </h2>
          <p
            data-aos="fade-up"
            data-aos-duration="400"
            data-aos-delay="100"
            class="text-gray-900 mt-2 text-3xl sm:text-4xl lg:text-6xl leading-8 font-extrabold tracking-tight mt-4 sm:mt-10"
          >
            nibble<br />
            da oltre 10 anni<br />promuove e condivide <br />l'innovazione di:
          </p>
        </div>
        <div class="col-span-12 mt-10">
          <div
            class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 items-center "
          >
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="0"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/mikrotik.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="100"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/cambium.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="200"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/lepida.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="300"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/mix.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="600"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/google-cloud.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="500"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/ripe-net.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="600"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/aruba.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="400"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/namex.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="600"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/timenet.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="700"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/cisco.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="800"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/hpe.png" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="800"
              class="px-8 sm:pr-16"
            >
              <img src="/assets/logos/openfiber.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlansSection",
  data() {
    return {
      head: {
        heading: {
          mode: true,
          text: "VOIP",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Come funziona?",
          color: "text-gray-8 text-left00",
        },
        subtitle: {
          mode: true,
          text:
            "Ok, ora sono curioso. Quanto tempo ci vuole a mettere la linea Nibble?",
          color: "text-gray-400",
        },
      },
    };
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>
