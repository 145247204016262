import clickOutside from "../directives/click-ouside.js";
import AOS from 'aos'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);
    AOS.init();
  }
};

export default GlobalDirectives;
