<template>
    <h1 class="text-gray-900 text-2xl md:text-4xl font-bold mt-6 mb-4">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: "TitleOne",
}
</script>