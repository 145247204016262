<template>
  <a
    target="_blank"
    :href="doc.dst_path"
    @mouseover="hoverDocument(doc)"
    @mouseleave="leaveDocument(doc)"
    class="cursor-pointer relative"
  >
    <div
      class="flex flex-row items-center hover:bg-gray-300 hover:text-gray-700  bg-gray-100 rounded-xl shadow-dark  py-2 sm:py-4 md:py-3 mb-4"
    >
      <div class="px-4 md:px-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-12 w-12"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="flex-grow px-4 flex justify-between">
        <div class="flex flex-col space-y-2">
          <div class="text-xl transition-all duration-500">
            {{ doc.name }}
          </div>
        </div>
        <div
          :class="{
            'text-green-600 hover:text-green-700': doc.isHover,
          }"
          class="hidden md:flex text-sm text-gray-100 flex-row-reverse items-center"
        >
          <div class="px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>Scarica subito</div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "ContractButton",
  props: ["doc"],
  methods: {
    hoverDocument(doc) {
      doc.isHover = true;
      return;
    },
    leaveDocument(doc) {
      doc.isHover = false;
      return;
    },
  },
};
</script>
