<template>
  <div class="relative overflow-hidden shadow-inner">
    <features-section
      :head="features.head"
      :panes="features.panes"
      :isDark="true"
    ></features-section>
  </div>
  <div class="relative overflow-hidden bg-gray-100">
    <app-landing-section></app-landing-section>
  </div>
  <div class="relative overflow-hidden bg-gray-900">
    <plans-section
      :isDark="plans.isDark"
      :head="plans.head"
      :details="plans.details"
    ></plans-section>
  </div>
  <div class="relative overflow-hidden bg-gray-100">
    <cashback-section></cashback-section>
  </div>
  <div class="relative overflow-hidden bg-gray-900">
    <additional-services-section
      clientType="private"
    ></additional-services-section>
  </div>
  <div class="relative overflow-hidden bg-gray-900">
    <contact-us-section></contact-us-section>
  </div>
</template>

<script>
import FeaturesSection from "@/sections/FeaturesSection";
import AppLandingSection from "@/sections/AppLandingSection";
import PlansSection from "@/sections/privates/PlansSection";
import CashbackSection from "@/sections/privates/CashbackSection";
import AdditionalServicesSection from "@/sections/AdditionalServicesSection";
import ContactUsSection from "@/sections/ContactUsSection";

export default {
  name: "PrivatesPage",
  components: {
    FeaturesSection,
    AppLandingSection,
    PlansSection,
    CashbackSection,
    ContactUsSection,
    AdditionalServicesSection,
  },
  data() {
    return {
      features: {
        head: {
          heading: {
            mode: true,
            text: "Stay Connected",
            color: "text-yellow-500",
          },
          title: {
            mode: true,
            text: "Se scegli nibble, non torni indietro",
            color: "text-gray-200",
          },
          subtitle: {
            mode: true,
            text:
              "I nostri punti di forza sono la presenza sul territorio, il rapporto diretto con gli utenti e un supporto tecnico competente ed efficiente nella risoluzione dei problemi.",
            color: "text-gray-400",
          },
        },
        panes: {
          tr: {
            title: "Rapidità",
            description:
              "Rapidità su tempi di installazione, assistenza al cliente e ripristino ai guasti.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />',
          },
          tl: {
            title: "Trasparenza",
            description:
              "Con noi il prezzo è bloccato, per sempre. <br>Nessun aumento dell'offerta in futuro.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>',
          },
          br: {
            title: "Privacy by design",
            description:
              "Il traffico è criptato e non viene archiviato. <br>Navighi libero e responsabilmente.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />',
          },
          bl: {
            title: "Rapporto diretto",
            description: "Ti garantiamo assistenza dedicata senza call center.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />',
          },
        },
      },
      plans: {
        isDark: true,
        head: {
          heading: {
            mode: true,
            text: "Pricing",
            color: "text-yellow-500",
            darkColor: "text-yellow-500",
          },
          title: {
            mode: true,
            text: "I nostri abbonamenti",
            color: "text-gray-200",
            darkColor: "text-gray-800",
          },
          subtitle: {
            mode: false,
          },
        },
        details: {
          first: {
            start: 2290,
            end: 2990,
            now: 2290,
            name: "Basic",
            shortName: "Basic",
            downRate: "10",
            upRate: "2",
            price: "22.90",
            price2: "29.90",
          },
          second: {
            start: 2990,
            end: 3990,
            now: 2990,
            name: "Advanced",
            shortName: "Advanced",
            downRate: "30",
            upRate: "5",
            price: "29.90",
            price2: "39.90",
          },
        },
      },
    };
  },
};
</script>
