<template>
  <div>
    <div class="relative overflow-hidden shadow-inner">
      <features-section
        :head="features.head"
        :panes="features.panes"
        :isDark="false"
      ></features-section>
    </div>
    <div class="relative overflow-hidden bg-gray-900">
      <app-landing-section :isDark="app_landing.isDark"></app-landing-section>
    </div>
    <div class="relative overflow-hidden bg-gray-100">
      <voip-section></voip-section>
    </div>
    <div class="relative overflow-hidden bg-gray-900">
      <plans-section
        :isDark="plans.isDark"
        :head="plans.head"
        :details="plans.details"
      ></plans-section>
    </div>
    <div class="relative overflow-hidden bg-gray-100">
      <partner-section></partner-section>
    </div>
    <div class="relative overflow-hidden bg-gray-900">
      <additional-services-section
        :clientType="'business'"
      ></additional-services-section>
    </div>
    <div class="relative overflow-hidden bg-gray-900">
      <contact-us-section></contact-us-section>
    </div>
  </div>
</template>

<script>
import FeaturesSection from "@/sections/FeaturesSection.vue";
import AppLandingSection from "@/sections/AppLandingSection.vue";
import VoipSection from "@/sections/business/VoipSection";
import PlansSection from "@/sections/business/PlansSection.vue";
import PartnerSection from "@/sections/business/PartnerSection";
import AdditionalServicesSection from "@/sections/AdditionalServicesSection";
import ContactUsSection from "@/sections/ContactUsSection";

export default {
  components: {
    FeaturesSection,
    AppLandingSection,
    PlansSection,
    AdditionalServicesSection,
    VoipSection,
    PartnerSection,
    ContactUsSection,
  },
  name: "BusinessInternetPage",
  data() {
    return {
      features: {
        head: {
          heading: {
            mode: true,
            text: "Stay Connected",
            color: "text-yellow-500",
            darkColor: "text-yellow-500",
          },
          title: {
            mode: true,
            text: "Le prestazioni della fibra.<br>La flessibilità del wireless",
            color: "text-gray-200",
            darkColor: "text-gray-800",
          },
          subtitle: {
            mode: true,
            text:
              "Servizio di connettività studiato su misura per la tua impresa.",
            color: "text-gray-400",
            darkColor: "text-gray-600",
          },
        },
        panes: {
          tr: {
            title: "Centralini VoIP in Cloud",
            description:
              "Porta con te il numero della tua Azienda. <br>Con i nostri centralini le chiamate passano anche dallo smartphone.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />',
          },
          tl: {
            title: "Dinamicità",
            description:
              "Ogni Azienda si differenzia per esigenze e necessità. <br>In un unico progetto soddisfiamo tutte le tue richieste.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />',
          },
          br: {
            title: "Infrastruttura proprietaria",
            description:
              "La nostra infrastruttura è completamente di proprietà per garantire la massima efficienza e zero compromessi.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />',
          },
          bl: {
            title: "Assistenza dedicata",
            description:
              "Ricevi assistenza specializzata da tecnici preparati a risolvere qualsiasi problematica.",
            icon:
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />',
          },
        },
      },
      app_landing: {
        isDark: true,
      },
      plans: {
        isDark: true,
        head: {
          heading: {
            mode: true,
            text: "Pricing",
            color: "text-yellow-500",
            darkColor: "text-yellow-500",
          },
          title: {
            mode: true,
            text: "I nostri abbonamenti",
            color: "text-gray-200",
            darkColor: "text-gray-800",
          },
          subtitle: {
            mode: false,
          },
        },
        details: {
          first: {
            start: 35,
            end: 35,
            now: 35,
            name: "15 Dealer",
            shortName: "Dealer",
            downRate: "15",
            upRate: "3",
            price: "35.00",
          },
          second: {
            start: 49,
            end: 49,
            now: 49,
            name: "30 Professional",
            shortName: "Professional",
            downRate: "30",
            upRate: "6",
            price: "49.00",
          },
          third: {
            start: 89,
            end: 89,
            now: 89,
            name: "30 Enterprise",
            shortName: "Enterprise",
            downRate: "50",
            upRate: "8",
            price: "89.00",
          },
        },
      },
    };
  },
};
</script>
