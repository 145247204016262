<template>
  <div class="max-w-7xl mx-auto py-5 sm:py-10 md:pb-40">
    <div class="mx-4 md:mx-6">
      <section-heading
        :alwaysCentered="true"
        :heading="head.heading"
        :title="head.title"
        :subtitle="head.subtitle"
      ></section-heading>
      <card-bg-gradient
        data-aos="fade-up"
        data-aos-duration="600"
        :heading="card.heading"
        :headingColor="card.headingColor"
        :title="card.title"
        :link="card.link"
        :paragraphs="card.paragraphs"
        :button="card.button"
        :bg="card.bg"
        :image="card.image"
      ></card-bg-gradient>
    </div>
  </div>
</template>

<script>
import CardBgGradient from "@/sections/elements/CardBgGradient";

export default {
  components: { CardBgGradient },
  name: "CashbackSection",
  data() {
    return {
      head: {
        heading: {
          mode: true,
          text: "Cashback",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Invita un amico...",
          color: "text-gray-900",
        },
        subtitle: {
          mode: true,
          text:
            "Se attiverà un servizio <strong>nibble</strong>, <br>per entrambi un mese di navigazione gratis",
          color: "text-gray-400",
        },
      },
      card: {
        heading: "Porta un amico",
        headingColor: "text-yellow-600",
        title: "Come funziona? <br>Semplicissimo.",
        link: "login",
        paragraphs: [
          "<ol class=\"list-decimal list-outside pl-7\"><li> Accedi all'area clienti su my.nibble.it </li><li> Compila il form nella sezione cashback </li><li> Attendi l'attivazione dell'amico</li></ol>",
          "\nAl resto ci pensiamo noi!",
          '<p class="hidden md:block">Che aspetti, invita i tuoi amici ad attivare una linea <strong>nibble</strong>: per ogni abbonamento sottoscritto riceverete entrambi un mese di navigazione gratis!</p>',
          // "E lo stesso premio spetterà anche a loro.",
        ],
        button: {
          isActive: true,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "Apri myNibble ",
          classes:
            "hover:from-gray-400 hover:to-gray-500 text-white from-gray-400 to-gray-600 hover:shadow-xl",
        },
        bg: "from-yellow-300 to-yellow-500 ",
        image: "/assets/img/research.svg",
      },
    };
  },
};
</script>
