<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div class="max-w-7xl mx-auto py-8 sm:py-16 md:py-20">
        <div class="flex flex-col mx-4 md:mx-10">
          <title-one>Trasparenza tariffaria</title-one>

          <paragraph>
            In ottemperanza alla delibera n. 252/16/CONS (che sostituisce la
            96/07/CONS), Nibble rende noti i prospetti informativi delle offerte
            al pubblico. I documenti riassuntivi inerenti tutte le offerte
            sottoscrivibili (e non sottoscrivibili, ma vigenti) sono disponibili
            di seguito, in formato .pdf:
          </paragraph>

          <!-- Condizione generali - inizio -->
          <title-two>Condizioni generali</title-two>
          <div class="grid grid-cols-1 md:grid-cols-3">
            <div
              class="mb-4 md:mr-4"
              v-for="(doc, index) in general_conditions_docs"
              :key="index"
            >
              <generic-document-button :doc="doc"></generic-document-button>
            </div>
          </div>
          <!-- Condizione generali - fine -->

          <!-- Contratti attuali - inizio -->
          <div class="grid md:grid-cols-2 md:space-x-4">
            <div class="col">
              <title-two>Contratti Home</title-two>

              <transition
                v-for="(doc, index) in home_docs"
                :key="index"
                :name="'slide-top-blur-2'"
              >
                <contract-button v-if="!isLoading" :doc="doc"></contract-button>
              </transition>
            </div>
            <div class="col">
              <title-two>Contratti Business</title-two>
              <transition
                v-for="(doc, index) in business_docs"
                :key="index"
                :name="'slide-top-blur-3'"
              >
                <contract-button v-if="!isLoading" :doc="doc"></contract-button>
              </transition>
            </div>
          </div>
          <!-- Contratti attuali - fine -->

          <!-- Contratti attuali - inizio -->
          <title-two>Servizi non più sottoscrivibili</title-two>
          <div class="flex flex-col bg-gray-200 rounded-md p-2">
            <ul class="disc-none">
              <li v-for="(doc, index) in obsoletes_docs" :key="index">
                <a
                  target="_blank"
                  class="flex flex-row space-x-3 hover:bg-opacity-50 rounded-sm hover:bg-yellow-500 items-center p-1.5 border-b border-gray-400"
                  :href="doc.dst_path"
                  :download="doc.filename"
                  @mouseover="hoverDocument(doc)"
                  @mouseleave="leaveDocument(doc)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                  <p class="text-sm text-gray-700">
                    {{ doc.name }}
                  </p>
                  <p class="flex-grow text-right flex flex-row-reverse">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span v-if="doc.isHover" class="hidden md:block text-sm">
                      Scarica subito
                    </span>
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import docs from "@/json/documents.json";
import ContractButton from "@/ui/buttons/ContractButton";
import GenericDocumentButton from "@/ui/buttons/GenericDocumentButton";

export default {
  name: "TariffTransparencyPage",
  components: {
    ContractButton,
    GenericDocumentButton,
  },
  data() {
    return {
      isLoading: true,
      docs: docs,
      served_file: "",
    };
  },
  computed: {
    privacy_docs() {
      return this.docs.filter((el) => {
        return el.category == "privacy";
      });
    },
    general_conditions_docs() {
      return this.docs.filter((el) => {
        return el.category == "legal" && el.sub == "general_conditions";
      });
    },
    home_docs() {
      return this.docs.filter((el) => {
        return el.category == "legal" && el.sub == "contracts_home";
      });
    },
    business_docs() {
      return this.docs.filter((el) => {
        return el.category == "legal" && el.sub == "contracts_business";
      });
    },
    obsoletes_docs() {
      return this.docs.filter((el) => {
        return el.category == "legal" && el.sub == "obsoletes";
      });
    },
  },
  mounted() {
    this.isLoading = !this.isLoading;
  },
  methods: {
    hoverDocument(doc) {
      doc.isHover = true;
      return;
    },
    leaveDocument(doc) {
      doc.isHover = false;
      return;
    },
  },
};
</script>

<style scoped>
/* slide top blur effect for blocks */
.slide-top-blur-2-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-3-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.25s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}
.slide-top-blur-4-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.5s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
