<template>
    <form @submit.prevent="onSubmit" class="text-lg">
        <div class="flex flex-col space-y-4 items-center my-4">
            <div
                class="flex-grow flex flex-row space-x-1 md:space-x-2 items-start w-full"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 md:h-6 w-5 md:w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <h1 class="text-base sm:text-lg md:text-xl">
                    Verifica la copertura al tuo indirizzo
                </h1>
            </div>
            <div class="flex-grow flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full">
                <div class="flex-grow flex flex-col">
                    <div v-if="formState != 'ending'">
                        <vue-google-autocomplete
                            ref="address"
                            id="map"
                            :fields="[
                                'address_components',
                                'adr_address',
                                'formatted_address',
                                'geometry',
                            ]"
                            class="w-full border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 p-2 md:p-4 text-base sm:text-lg md:text-xl"
                            classname="form-control"
                            placeholder="Inserisci il tuo indirizzo"
                            v-on:placechanged="getAddressData"
                            v-on:input="resetError()"
                            country="it"
                        >
                        </vue-google-autocomplete>
                    </div>
                    <div v-else class="p-2 md:p-4 text-base md:text-md bg-gray-300">
                        <p>
                            {{ address }}
                        </p>
                    </div>
                </div>
                <div class="flex-shrink">
                    <submit-button
                        v-if="formState != 'ending'"
                        class="py-8"
                        :state="formState"
                        type="submit"
                        >Verifica</submit-button
                    >
                    <div v-else @click="resetForm()">
                        <div
                            class="py-4 pl-5 bg-transparent cursor-pointer hidden md:block"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-7 w-7"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <div
                            class="py-2 pl-5 bg-transparent text-gray-500 cursor-pointer flex flex-row-reverse space-x-4 items-center content-end md:hidden"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            <p>Cancella ricerca</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-grow flex flex-row w-full" v-if="error">
                <p class="text-red-500 text-sm md:text-md">
                    Per procedere è necessario selezionare un indirizzo corretto
                </p>
            </div>
        </div>
    </form>
</template>

<script>
import SubmitButton from "./../../ui/buttons/SubmitButton";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import json from "@/json/coverage-area.json";

export default {
    name: "CheckCoverageForm",
    components: { SubmitButton, VueGoogleAutocomplete },
    emits: ["loadResult"],
    data() {
        return {
            address: "",
            postal_code: "",
            formState: "ready",
            coverage_area: json,
            error: false,
        };
    },
    methods: {
        getAddressData(addressData, placeResultData) {
            this.address = placeResultData.formatted_address;
            this.postal_code = addressData.postal_code;
        },
        searchAddress() {
            return new Promise((resolve) => {
                let result = [];
                this.coverage_area.forEach((technology) => {
                    if (technology.postal_codes.includes(this.postal_code))
                        result.push(technology.name);
                });
                resolve(result);
                return result;
            });
        },
        async onSubmit() {
            if (this.postal_code != "") {
                this.error = false;
                this.formState = "loading";

                let result = await this.searchAddress();

                this.$emit("loadResult", {
                    tech_available: result,
                    action: "show",
                });

                this.formState = "ending";
            } else {
                this.error = true;
            }
        },
        resetForm() {
            this.address = "";
            this.postal_code = "";
            this.formState = "ready";
            this.error = false;
            this.$emit("loadResult", { action: "hide" });
        },
        resetError() {
            this.error = false;
        },
    },
};
</script>