<template>
  <div class="flex md:pb-4">
    <div class="flex-grow md:text-center">
      <h1
        class="text-gray-700 text-3xl sm:text-2xl md:text-4xl lg:text-5xl w-5/6 md:w-full"
      >
        Abbonamento
      </h1>
      <h1
        class="text-gray-900 font-bold text-3xl sm:text-2xl md:text-4xl lg:text-5xl w-5/6 md:w-full"
      >
        {{ data.model.downRate }}
        <span class="uppercase" v-if="data.type == 'privates'">
          {{ activation }}
        </span>
        {{ data.model.shortName }}
      </h1>
    </div>
  </div>
  <div
    class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6 xl:space-x-10 lg:justify-center py-8 lg:py-12"
  >
    <div
      class="flex flex-grow lg:flex-grow-0 items-center md:justify-center rounded-md bg-gradient-to-r from-gray-50 to-gray-300 p-2 lg:p-4 text-gray-900 shadow-xl"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-10 lg:w-14 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
        </svg>
      </div>
      <div class="px-3">
        <span class="text-xl lg:text-2xl"> {{ data.downloadRate }} Mb/s </span>
        <br />
        <span class="text-sm md:text-md">
          velocità in <strong>download</strong></span
        >
      </div>
    </div>
    <div
      class="flex flex-grow lg:flex-grow-0 items-center md:justify-center rounded-md bg-gradient-to-r from-gray-200 to-gray-400 p-2 lg:p-4 text-gray-900 shadow-xl"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-10 lg:w-14 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
      </div>
      <div class="px-3">
        <span class="text-xl lg:text-2xl"> {{ data.uploadRate }} Mb/s </span>
        <br />
        <span class="text-sm md:text-md">
          velocità in <strong>upload</strong></span
        >
      </div>
    </div>
    <div
      class="flex flex-grow lg:flex-grow-0 items-center md:justify-center rounded-md bg-gradient-to-r from-gray-400 to-gray-200 p-2 lg:p-4 text-gray-900 shadow-xl"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="w-10 lg:w-14 mr-2"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="px-3">
        <span class="text-xl lg:text-2xl">
          € {{ activation == "easy" ? data.model.price : data.model.price2 }} /
          mese
        </span>
        <br />
        <span class="text-sm md:text-md"> prezzo fisso</span>
      </div>
    </div>
  </div>
  <div class="flex md:justify-center">
    <div class="flex-shrink text-gray-700 text-lg lg:m-8">
      Senza limiti di tempo e di traffico.
    </div>
  </div>

  <div class="flex mt-8 pb-2 border-b-2 border-gray-700">
    <div class="flex-grow text-left">
      <h1 class="text-gray-700 text-xl font-bold">ATTIVAZIONE</h1>
    </div>
    <div
      class="flex-shrink px-2 text-lg cursor-pointer"
      @click="activation = 'easy'"
      :class="activation != 'easy' ? 'text-gray-900' : 'text-yellow-500'"
    >
      Easy
    </div>
    <div
      class="flex-shrink px-2 text-lg cursor-pointer"
      @click="activation = 'free'"
      :class="activation != 'free' ? 'text-gray-900' : 'text-yellow-500'"
    >
      Free
    </div>
  </div>

  <div
    class="flex flex-col lg:flex-row justify-center space-y-4 lg:space-y-0 lg:space-x-10 pt-8 mb-4"
  >
    <div
      v-if="activation == 'easy'"
      class="flex-1 flex flex-col rounded-md bg-gradient-to-r from-gray-50 to-gray-200 py-4 lg:py-6 px-4 lg:px-8 xl:px-12 text-gray-900 shadow-xl"
    >
      <div class="flex items-center justify-between lg:space-x-4">
        <div class="flex flex-col space-y-1 pr-6">
          <span class="text-2xl lg:text-3xl"> Easy </span>
          <span class="text-md"> VINCOLO 24 MESI </span>
        </div>
        <div class="text-3xl lg:text-2xl xl:text-4xl whitespace-nowrap">
          € 0.00 *
        </div>
      </div>
      <div class="flex mt-4">
        <span class="text-sm">
          * Penale per recesso anticipato € 199.00. Comprende il recupero
          materiali in comodato d'uso gratuito
        </span>
      </div>
    </div>
    <div
      v-if="activation == 'free'"
      class="flex-1 flex flex-col rounded-md bg-gradient-to-r from-gray-700 to-gray-500 py-4 lg:py-6 px-4 lg:px-8 xl:px-12 text-gray-200 shadow-xl"
    >
      <div class="flex items-center justify-between lg:space-x-4">
        <div class="flex flex-col space-y-1 pr-6">
          <span class="text-2xl lg:text-3xl"> Free </span>
          <span class="text-md"> SENZA VINCOLO </span>
        </div>
        <div class="text-3xl lg:text-2xl xl:text-4xl whitespace-nowrap">
          € 0.00
        </div>
      </div>
      <div class="flex mt-4">
        <span class="text-sm">
          Il cliente può decidere di cessare il contratto in qualsiasi momento.
          <br />
          In caso di Recesso verrà fatturato l'importo di € 30.00 per il
          recupero dei materiali in comodato d'uso gratuito da parte di Tecnico
          Certificato Nibble
        </span>
      </div>
    </div>
  </div>

  <div class="flex py-6">
    <div class="flex-shrink text-gray-700 text-base">
      <p class="text-base">
        Per quanto non espressamente riportato, si rimanda alle condizioni
        generali di contratto reperibili nella sezione
        <router-link to="/legal/tariff-transparency"
          >trasparenza tariffaria</router-link
        >.
      </p>
    </div>
  </div>

  <div class="flex mt-8 pb-2 border-b-2 border-gray-700">
    <div class="flex-grow text-left">
      <h1 class="text-gray-700 text-xl font-bold">INSTALLAZIONE</h1>
    </div>
    <div
      class="flex-shrink px-2 text-lg cursor-pointer"
      @click="installation = 'freedom'"
      :class="installation != 'freedom' ? 'text-gray-900' : 'text-yellow-500'"
    >
      Freedom
    </div>
    <div
      class="flex-shrink px-2 text-lg cursor-pointer"
      @click="installation = 'classic'"
      :class="installation != 'classic' ? 'text-gray-900' : 'text-yellow-500'"
    >
      Classic
    </div>
    <div
      class="flex-shrink px-2 text-lg cursor-pointer"
      @click="installation = 'smart'"
      :class="installation != 'smart' ? 'text-gray-900' : 'text-yellow-500'"
    >
      Smart
    </div>
  </div>

  <div class="flex flex-col lg:flex-row-reverse pt-8 mb-4">
    <div class="flex-1 pt-4 pb-4 lg:pb-0">
      <img src="/assets/undraw/installation.svg" alt="" />
    </div>
    <div class="flex-1 lg:pr-24 text-md text-gray-700 tracking-wide">
      <p class="text-md text-gray-500 mb-4 uppercase tracking-normal font-mono">
        Installazione: {{ installation }}
      </p>
      <p
        class="text-4xl font-bold text-gray-900 mb-4"
        v-html="installationPrice"
      ></p>
      <p class="mb-2" v-if="installation == 'freedom'">
        Ideale se vieni da un altro operatore Wireless. <br />
        L’intervento consiste nell’uscita di un Tecnico che effettuerà
        Installazione Antenna e test di collegamento su impianto già esistente
      </p>
      <p class="mb-2" v-if="installation == 'classic'">
        L'intervento consiste nell'installazione presso vostra abitazione di
        un’antenna ricevente da parte di un nostro Tecnico Certificato. Include
        il montaggio dell’antenna su un palo già esistente sul tetto/balcone per
        un massimo di 4 Ore lavorative e la posa del cavo fino a una lunghezza
        massima di 25 metri. <br /><br />
        In caso di intervento fuori standard (ad esempio se non è disponibile un
        palo o se quello presente non è adeguato), eventuali integrazioni
        verranno prima quantificate dal Tecnico in fase di Installazione.
      </p>
      <p class="mb-2" v-if="installation == 'smart'">
        L'intervento consiste nell'installazione presso vostra abitazione di
        un’antenna ricevente da parte di un nostro Tecnico Certificato. Include
        il montaggio dell’antenna su un palo già esistente sul tetto/balcone per
        un massimo di 4 Ore lavorative e la posa del cavo fino a una lunghezza
        massima di 25 metri. <br /><br />
        In caso di intervento fuori standard (ad esempio se non è disponibile un
        palo o se quello presente non è adeguato), eventuali integrazioni
        verranno prima quantificate dal Tecnico in fase di Installazione.
      </p>
    </div>
  </div>

  <div class="w-max">
    <base-button @click="goToReference()" class="shadow-md hover:shadow-xl">
      Contattaci per maggiori informazioni
    </base-button>
  </div>

  <div class="h-2 md:h-48"></div>
</template>

<script>
export default {
  name: "PrivatesPlanModal",
  emit: ["closeModal"],
  props: ["data"],
  data() {
    return {
      activation: "easy",
      installation: "freedom",
    };
  },
  computed: {
    installationPrice() {
      switch (this.installation) {
        case "freedom":
          return "€ 30.00";
        case "classic":
          return "€ 80.00";
        case "smart":
          return `€ 5.00 <span class="text-xl"> / al mese per 24 rate </span>`;
        default:
          return "";
      }
    },
  },
  methods: {
    goToReference() {
      this.$emit("closeModal");
      this.$router.push("/legal/contact-us");
    },
  },
};
</script>
