<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div class="max-w-7xl mx-auto py-8 sm:py-16 md:py-20">
        <div class="flex flex-col space-y-6 md:space-y-8 mx-4 md:mx-10">
          <title-one>Carta dei servizi</title-one>
          <parapgraph>
            Nell'ottica di garantire la trasparenza nei rapporti con gli utenti
            e in ottemperanza alla Direttiva del Consiglio dei Ministri del
            27/1/1994 relativa ai "Principi sull'erogazione dei servizi
            pubblici", alla Delibera n. 179/03/CSP "Approvazione della direttiva
            generale in materia di qualità e carte dei servizi di
            telecomunicazioni ai sensi dell'art. 1, comma 6, lettera b) numero 2
            della legge 31 luglio 1997, n. 249", nonché alla Delibera n.
            131/06/CSP "Approvazione della direttiva in materia di qualità e
            carte dei servizi di accesso ad internet da postazione fissa, ai
            sensi dell'articolo 1, comma 6, lettera b), numero 2 della legge 31
            luglio 1997, n. 249" e alla successiva Delibera 244/08/CSP e
            seguenti, nonché delle Delibere AGCOM 254/04/CSP "Approvazione della
            direttiva in materia di qualità e carte dei servizi di telefonia
            vocale fissa ai sensi dell’articolo 1, comma 6 lett. b) n. 2 della
            legge 31 Legge 1997, n. 249", e Delibera AGCOM 79/09/CSP "Direttiva
            in materia di qualità dei servizi telefonici di contatto (call
            center) nel settore delle comunicazioni elettroniche", è possibile
            consultare la Carta dei Servizi, disponibile in formato .pdf.
          </parapgraph>

          <title-two> Documenti </title-two>

          <div class="grid md:grid-cols-3">
            <div
              class="mb-4 md:mr-4"
              v-for="(doc, index) in services_docs"
              :key="index"
            >
              <generic-document-button :doc="doc"></generic-document-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import docs from "@/json/documents.json";
import GenericDocumentButton from "@/ui/buttons/GenericDocumentButton";

export default {
  name: "ServiceCharterPage",
  components: { GenericDocumentButton },
  data() {
    return {
      isLoading: true,
      docs: docs,
      served_file: "",
    };
  },
  computed: {
    privacy_docs() {
      return this.docs.filter((el) => {
        return el.category == "privacy";
      });
    },
    services_docs() {
      return this.docs.filter((el) => {
        return el.category == "legal" && el.sub == "service_charter";
      });
    },
  },
  mounted() {
    this.isLoading = !this.isLoading;
  },
  methods: {
    hoverDocument(filepath, subdir = this.services_docs) {
      console.log("hover");
      subdir
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = true;
        });
      return;
    },
    leaveDocument(filepath, subdir = this.services_docs) {
      subdir
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = false;
        });
      return;
    },
  },
};
</script>

<style scoped>
/* slide top blur effect for blocks */
.slide-top-blur-2-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-3-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.25s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}
.slide-top-blur-4-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.5s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
