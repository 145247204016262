<template>
  <div class="max-w-7xl mx-auto py-5 sm:py-10 md:pb-40">
    <div class="mx-4 md:mx-6">
      <div class="grid md:grid-cols-3 gap-2 md:gap-10">
        <!-- Scheda 1 -->
        <div
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-delay="0"
          class="bg-gray-900 shadow-md text-gray-100 text-center rounded-md p-1.5 md:p-5 flex flex-row space-x-1 sm:space-x-2 md:space-x-6"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-20 w-20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="flex flex-col flex-grow justify-evenly items-start">
            <p class="text-lg md:text-xl">
              Chiama i cellulari da
            </p>
            <p class="text-3xl md:text-3xl">
              € 0.18 / min
            </p>
          </div>
        </div>

        <!-- Scheda 2 -->
        <div
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-delay="150"
          class="bg-gray-900 shadow-md text-gray-100 text-center rounded-md p-1.5 md:p-5 flex flex-row space-x-1 sm:space-x-2 md:space-x-6"
        >
          <div class="mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-20 w-20 px-4 sm:px-3 md:px-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
              />
            </svg>
          </div>
          <div class="flex flex-col flex-grow justify-evenly items-start">
            <p class="text-lg md:text-xl">
              Chiama i fissi da
            </p>
            <p class="text-3xl md:text-3xl">
              € 0.015 / min
            </p>
          </div>
        </div>

        <!-- Scheda 3 -->
        <div
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-delay="300"
          class="bg-gray-900 shadow-md text-gray-100 text-left rounded-md p-1.5 md:p-5 flex flex-row items-center"
        >
          <p class="text-lg md:text-2xl leading-8 mx-4">
            Tariffazione al secondo <br />
            senza scatto alla risposta
          </p>
        </div>
      </div>

      <div class="my-8">
        <section-heading
          :alwaysCentered="true"
          :heading="head.heading"
          :title="head.title"
          :subtitle="head.subtitle"
        >
        </section-heading>
      </div>

      <div :class="isOpen ? 'gap-4 md:gap-10 lg:gap-24' : 'gap-8'" class="grid grid-cols-2">
        <!-- PREPAID -->
        <div>
          <div class="rounded-md shadow-md">
            <div
              class="flex flex-col-reverse items-end sm:flex-row sm:items-center bg-gradient-to-r from-gray-200 to-gray-300 transition-all duration-600 shadow-md z-50 rounded-md"
            >
              <div
                :class="isOpen ? '' : ''"
                class="p-2 sm:px-4 md:px-8 sm:py-4 md:py-6 text-left flex-grow "
              >
                <h1 class="text-xl sm:text-3xl md:text-4xl font-bold mb-3">Linea Postpagata</h1>
                <p class="text-lg hidden md:block ">Usufruisci di tariffazioni</p>
                <p class="text-lg hidden md:block ">agevolate e servizi personalizzati</p>
              </div>
              <div
                :class="isOpen ? 'w-32 pb-0 sm:pb-2 pt-4 sm:pt-2' : 'w-72 py-4'"
                class="transition-all duration-600 ease-in-out"
              >
                <img
                  src="/assets/img/postpaid.png"
                  class=""
                  data-aos="fade-right"
                  data-aos-duration="400"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="rounded-md shadow-md">
            <div
              class="flex flex-col items-start sm:flex-row sm:items-center bg-gradient-to-r from-gray-200 to-gray-300 transition-all duration-600 rounded-md"
            >
              <div
                :class="isOpen ? 'w-32 pb-0 sm:pb-2 pt-4 sm:pt-2' : 'w-72 py-4'"
                class="transition-all duration-600 ease-in-out "
              >
                <img
                  src="/assets/img/prepaid.png"
                  class=""
                  data-aos="fade-left"
                  data-aos-duration="400"
                />
              </div>
              <div
                :class="isOpen ? '' : ''"
                class="p-2 sm:px-4 md:px-8 sm:py-4 md:py-6 text-right flex-grow "
              >
                <h1 class="text-xl sm:text-3xl md:text-4xl font-bold mb-3">Linea Prepagata</h1>
                <p class="text-lg hidden md:block ">Ricarica in autonomia</p>
                <p class="text-lg hidden md:block ">il tuo credito telefonico</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-10 sm:px-10">
        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="50" :data="chart[0]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
          />
        </comparison-row>

        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="150" :data="chart[1]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
          />
        </comparison-row>

        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="200" :data="chart[2]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"
          />
        </comparison-row>

        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="250" :data="chart[3]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </comparison-row>

        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" :data="chart[4]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
          />
        </comparison-row>

        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="350" :data="chart[5]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </comparison-row>
        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="400" :data="chart[6]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </comparison-row>
        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="450" :data="chart[7]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
          />
        </comparison-row>
        <comparison-row  data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" :data="chart[8]">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
          />
        </comparison-row>
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonRow from "@/ui/tables/ComparisonRow";

export default {
  name: "FeaturesSection",
  components: { ComparisonRow },
  data() {
    return {
      isOpen: true,
      head: {
        heading: {
          mode: false,
          text: "Flow",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Vuoi mantenere il tuo numero fisso?",
          color: "text-gray-900",
        },
        subtitle: {
          mode: true,
          text:
            "Nessun problema! Scopri tutte le nostre offerte Voce e potrai continuare ad utilizzare il tuo numero!",
          color: "text-gray-400",
        },
      },
      chart: [
        {
          sx: "€ 0.02",
          title: "Chiamate ai fissi",
          dx: "€ 0.015",
        },
        {
          sx: "€ 0.20",
          title: "Chiamate ai cellulari",
          dx: "€ 0.18",
        },
        {
          sx: "€ 0",
          title: "Canone annuo",
          dx: "€ 60",
        },
        {
          sx:
            '<span class="text-xl ">1</span><br /><div class="text-sm text-gray-600 leading-3">Potrai ricevere o effettuare una sola chiamata alla volta</div>',
          title: "Canali simulanei",
          dx: "20",
        },
        {
          sx: '<span class="text-2xl">✕</span>',
          title:
            "Chiamate verso numeri speciali (899, 199, ...),<br /> ripartiti (848, 840, ...) e satellitari",
          dx: '<span class="text-2xl">✓</span>',
        },
        {
          sx: '<span class="text-2xl">✓</span>',
          title: "Chiamate verso numeri di emergenze e Numeri Verdi",
          dx: '<span class="text-2xl">✓</span>',
        },
        {
          sx: '<span class="text-2xl">✓</span>',
          title: "Backup su un numero di Cellulare",
          dx: '<span class="text-2xl">✓</span>',
        },
        {
          sx: '<span class="text-2xl">✓</span>',
          title:
            'Deviazione di chiamata <br/> <span class="text-sm leading-3">(Su non Risposta o su Occupato Vs un Cellulare o VoiceMail)</span>',
          dx: '<span class="text-2xl">✓</span>',
        },
        {
          sx: '<span class="text-2xl">✓</span>',
          title:
            'VoiceMail  <br/> <span class="text-sm leading-3">(Segreteria Telefonica Gratuita sulla propria casella Mail)',
          dx: '<span class="text-2xl">✓</span>',
        },
      ],
    };
  },
};
</script>
