<template>
  <div class="max-w-7xl mx-auto py-5 sm:py-10 md:py-20">
    <div class="mx-4 md:mx-6">
      <div
        class="grid grid-cols-6 sm:grid-cols-12 items-center xl:items-end mt-10 pb-10 mb-4"
      >
        <div class="hidden lg:block lg:col-span-7 xl:col-span-6 business-woman">
          <img
            data-aos="fade-up"
            data-aos-duration="500"
            src="/assets/undraw/business_woman.svg"
            class="pl-20"
            alt=""
          />
        </div>
        <div class="col-span-6 sm:col-span-12 lg:col-span-5 xl:col-span-6">
          <div class="flex flex-col ">
            <div>
              <p class="text-md text-indigo-500 font-bold">SERVIZIO VOIP</p>
            </div>
            <div data-aos="fade-up" data-aos-duration="400" class="mt-10 mb-4">
              <p
                class="text-gray-800 mt-2 text-3xl sm:text-4xl lg:text-6xl leading-8 font-extrabold tracking-tight aos-init aos-animate"
              >
                Hai bisogno di una linea telefonica?
              </p>
            </div>

            <div
              class=" flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4 py-2 mt-8 mb-20"
            >
              <div
                class="flex-shrink"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="0"
              >
                <div
                  class="sm:w-max flex flex-row items-center bg-indigo-100 shadow-md"
                >
                  <div class="flex-shrink">
                    <img
                      src="/assets/icons/relax-with-book.png"
                      class="w-14 m-3 p-1"
                    />
                  </div>
                  <div
                    class="py-3 pr-10 text-lg xl:text-xl leading-7 text-gray-700"
                  >
                    Linea telefonica <br />
                    <strong>PRE</strong>PAGATA
                  </div>
                </div>
              </div>
              <div
                class="flex-shrink"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <div
                  class="sm:w-max flex flex-row items-center shadow-md bg-indigo-100"
                >
                  <div
                    class=""
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img src="/assets/icons/lazy.png" class="w-14 m-3 p-1" />
                  </div>
                  <p
                    class="py-3 pr-10 text-lg xl:text-xl leading-7 text-gray-700 "
                  >
                    Linea telefonica <br />
                    <strong>POST</strong>PAGATA
                  </p>
                </div>
              </div>
            </div>
            <div class=" ">
              <div class="grid grid-cols-1 sm:grid-cols-6 items-start">
                <div
                  class="order-2 sm:order-1 col-span-1 xs:col-span-4 sm:col-span-2 lg:col-span-6"
                >
                  <div data-aos="fade-up" data-aos-duration="500">
                    <p class="text-lg sm:text-xl font-medium text-gray-700">
                      Nibble VOIP è il servizio che ti permette di telefonare
                      tramite internet e risparmiare rispetto al costo degli
                      operatori telefonici tradizionali. <br /><br />
                      Chiami in Italia e in tutto il mondo senza scatto alla
                      risposta!
                    </p>
                  </div>
                  <div data-aos="fade-up" class="flex flex-row mt-10">
                    <router-link
                      to="/voip"
                      class="bg-gradient-to-r from-indigo-100 hover:from-indigo-200 to-indigo-300 hover:to-indigo-400 hover:shadow-md shadow-sm cursor-pointer text-gray-700 hover:text-gray-900 px-10 py-4 text-lg rounded-md"
                    >
                      Scopri di più
                    </router-link>
                  </div>
                </div>
                <div
                  class="order-1 sm:order-2 col-span-1 xs:col-span-2 sm:col-span-4 lg:hidden business-woman"
                >
                  <img
                    data-aos="fade-up"
                    data-aos-duration="500"
                    src="/assets/undraw/business_woman.svg"
                    alt=""
                    class="w-max pb-10 sm:pb-0 sm:pr-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <card-bg-gradient
        class="mb-40 mt-10"
        data-aos="fade-up"
        :isLight="fourthCard.isLight"
        :heading="fourthCard.heading"
        :headingColor="fourthCard.headingColor"
        :title="fourthCard.title"
        :paragraphs="fourthCard.paragraphs"
        :button="fourthCard.button"
        :bg="fourthCard.bg"
        :image="fourthCard.image"
      ></card-bg-gradient>
    </div>
  </div>
</template>

<script>
import CardBgGradient from "@/sections/elements/CardBgGradient";
export default {
  components: { CardBgGradient },
  name: "VoipSection",
  data() {
    return {
      head: {
        heading: {
          mode: true,
          text: "VOIP",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Come funziona?",
          color: "text-gray-8 text-left00",
        },
        subtitle: {
          mode: true,
          text:
            "Ok, ora sono curioso. Quanto tempo ci vuole a mettere la linea Nibble?",
          color: "text-gray-400",
        },
      },
      fourthCard: {
        isLight: false,
        heading: "Cloud PBX",
        headingColor: "text-indigo-100",
        title: "Scopri il centralino in Cloud che ti fa risparmiare",
        paragraphs: [
          '<div class="flex flex-row space-x-4"><span><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span><span>Dai alla tua azienda un\'immagine professionale, fin dal primo contatto telefonico con il cliente.</span></div>',
          '<div class="flex flex-row space-x-4"><span><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-7a2 2 0 012-2h2m3-4H9a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-1m-1 4l-3 3m0 0l-3-3m3 3V3" /></svg></span><span>Risparmia tempo e denaro con prezzi mensili all-inclusive.</span></div>',
          '<div class="flex flex-row space-x-4"><span><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg></span><span>Porta il tuo ufficio sempre con te.</span></div>',
          '<div class="flex flex-row space-x-4"><span><svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" /></svg></span><span>Passa le chiamate ai tuoi colleghi in estrema semplicità.</span></div>',
        ],
        button: {
          isActive: false,
          text: "Contattaci",
          classes: "from-indigo-50 to-indigo-200",
        },
        bg: "from-indigo-500 to-indigo-700",
        image: "/assets/undraw/cloud_sync.svg",
      },
    };
  },
};
</script>

<style scoped>
.business-woman {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
</style>
