import { createRouter, createWebHistory } from 'vue-router';

import TheFooter from './layout/TheFooter'
import BlankView from './layout/BlankView'
// import BlankHeaderView from './layout/BlankHeaderView'

import PrivatesHeader from './layout/headers/PrivatesHeader'
import BusinessHeader from './layout/headers/BusinessHeader'
import VoipHeader from './layout/headers/VoipHeader'
import AboutHeader from './layout/headers/AboutHeader'
import SupportHeader from './layout/headers/SupportHeader'
import LegalHeader from './layout/headers/LegalHeader'

import PrivatesPage from './pages/PrivatesPage';
import BusinessPage from './pages/BusinessPage';
import VoipPage from './pages/VoipPage';
import AboutPage from './pages/AboutPage';

// Support Pages
import SupportPage from './pages/support/SupportPage';
import FAQPage from './pages/support/FAQPage';
import CoveragePage from './pages/support/CoveragePage';
import JobsPage from './pages/support/JobsPage';
// import PartnershipPage from './pages/support/PartnershipPage';

// Legal Pages
import TariffTransparencyPage from './pages/legal/TariffTransparencyPage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import ServiceCharterPage from './pages/legal/ServiceCharterPage';
import ContactsPage from './pages/legal/ContactsPage';

// import BlankComponent from './ui/BlankComponent.vue';

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: [
    { path: '/', redirect: '/privates' },
    {
      path: '/privates',
      name: 'privates',
      components: {
        header: PrivatesHeader,
        default: PrivatesPage,
        footer: TheFooter,
      }
    },
    {
      path: '/business',
      name: 'business',
      components: {
        header: BusinessHeader,
        default: BusinessPage,
        footer: TheFooter,
      }
    },
    {
      path: '/voip',
      name: 'voip',
      components: {
        header: VoipHeader,
        default: VoipPage,
        footer: TheFooter,
      }
    },
    {
      path: '/about',
      name: 'about',
      components: {
        header: AboutHeader,
        default: AboutPage,
        footer: TheFooter,
      }
    },
    {
      path: '/login',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://my.nibble.it")
      }
    },
    {
      path: '/support',
      name: 'Support',
      components: {
        header: SupportHeader,
        default: BlankView,
        footer: TheFooter
      },
      children: [
        {
          path: '',
          redirect: '/support/start'
        },
        {
          path: 'start',
          name: 'start',
          component: SupportPage
        },
        {
          path: 'faq',
          name: 'FAQ',
          component: FAQPage
        },
        {
          path: 'check-coverage',
          name: 'Coverage',
          component: CoveragePage
        },
        {
          path: 'jobs',
          name: 'Jobs',
          component: JobsPage
        },
        { path: 'partnership', redirect: '/legal/contact-us' },
        // {
        //   path: 'partnership',
        //   name: 'Partner Program',
        //   component: PartnershipPage
        // }
      ]
    },
    {
      path: '/legal',
      name: 'Legal Area',
      components: {
        header: LegalHeader,
        default: BlankView,
        footer: TheFooter
      },
      children: [
        {
          path: '',
          redirect: '/legal/privacy-policy'
        },
        {
          path: 'privacy-policy',
          name: 'privacy-policy',
          component: PrivacyPolicyPage
        },
        {
          path: 'tariff-transparency',
          name: 'tariff-transparency',
          component: TariffTransparencyPage
        },
        {
          path: 'service-charter',
          name: 'service-charter',
          component: ServiceCharterPage
        },
        {
          path: 'contact-us',
          name: 'ContactsPage',
          component: ContactsPage
        }
      ]
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
});

export default router;
