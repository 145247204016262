<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    :class="!isDark ? 'from-gray-100 to-gray-200' : 'from-gray-800 to-gray-900'"
    class="pb-16 lg:pb-20 lg:pb-28 bg-gradient-to-t "
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <section-heading
        :alwaysCentered="false"
        :heading="head.heading"
        :title="head.title"
        :subtitle="head.subtitle"
        :isDark="isDark"
      ></section-heading>

      <div class="mt-10">
        <dl
          class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="0"
                class="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-tr from-yellow-300 to-yellow-500 text-white"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  v-html="panes.tl.icon"
                ></svg>
              </div>
            </div>
            <div class="ml-4">
              <dt
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="100"
                :class="!isDark ? 'text-gray-800' : 'text-gray-200'"
                class="text-lg leading-6 font-medium"
              >
                {{ panes.tl.title }}
              </dt>
              <dd
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="200"
                class="mt-2 text-base"
                :class="!isDark ? 'text-gray-600' : 'text-gray-400'"
                v-html="panes.tl.description"
              ></dd>
            </div>
          </div>

          <div class="flex">
            <div class="flex-shrink-0">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="500"
                class="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-tr from-yellow-300 to-yellow-500 text-white"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  v-html="panes.tr.icon"
                ></svg>
              </div>
            </div>
            <div class="ml-4">
              <dt
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="300"
                :class="!isDark ? 'text-gray-800' : 'text-gray-200'"
                class="text-lg leading-6 font-medium"
              >
                {{ panes.tr.title }}
              </dt>
              <dd
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="900"
                v-html="panes.tr.description"
                class="mt-2 text-base"
                :class="!isDark ? 'text-gray-600' : 'text-gray-400'"
              ></dd>
            </div>
          </div>

          <div class="flex">
            <div class="flex-shrink-0">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="600"
                class="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-tr from-yellow-300 to-yellow-500 text-white"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  v-html="panes.bl.icon"
                ></svg>
              </div>
            </div>
            <div class="ml-4">
              <dt
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="400"
                :class="!isDark ? 'text-gray-800' : 'text-gray-200'"
                class="text-lg leading-6 font-medium"
              >
                {{ panes.bl.title }}
              </dt>
              <dd
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="700"
                class="mt-2 text-base"
                :class="!isDark ? 'text-gray-600' : 'text-gray-400'"
                v-html="panes.bl.description"
              ></dd>
            </div>
          </div>

          <div class="flex">
            <div class="flex-shrink-0">
              <div
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="800"
                class="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-tr from-yellow-300 to-yellow-500 text-white"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  v-html="panes.br.icon"
                ></svg>
              </div>
            </div>
            <div class="ml-4">
              <dt
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="1000"
                :class="!isDark ? 'text-gray-800' : 'text-gray-200'"
                class="text-lg leading-6 font-medium"
              >
                {{ panes.br.title }}
              </dt>
              <dd
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="1100"
                class="mt-2 text-base"
                :class="!isDark ? 'text-gray-600' : 'text-gray-400'"
                v-html="panes.br.description"
              ></dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "FeaturesSection",
  props: ["head", "panes", "isDark"],
};
</script>
