<template>
    <div class="mt-3 sm:mt-0 sm:ml-3">
        <button
            :type="type"
            class="w-full flex px-8 py-3 rounded-md text-gray-700 bg-yellow-300 hover:bg-yellow-400 hover:text-gray-900 md:py-4 md:text-lg md:px-10"
        >
            <slot></slot>
        </button>
    </div>
</template>

<script>
export default {
    name: "BaseButton",
    props: ["type"],
};
</script>