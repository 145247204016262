<template>
  <div>
    <img class="h-8 w-auto sm:h-10" src="/assets/logos/nibble.svg" />
  </div>
</template>

<script>
export default {
  name: "BaseLogo",
};
</script>
