<template>
  <div class="max-w-7xl mx-auto py-5 sm:py-4 md:py-20">
    <div class="grid grid-cols-6">
      <div class="col-span-2 sm:col-span-3 md:col-span-4 sm:px-8 md:py-4">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="0"
            data-aos-duration="300"
            class="-ml-16 sm:ml-0 z-30"
          >
            <img class="" src="/assets/img/landing_1.png" alt="" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
            data-aos-offset="300"
            data-aos-duration="300"
            class="hidden md:block z-20"
          >
            <img src="/assets/img/landing_2.png" alt="" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="200"
            data-aos-offset="400"
            data-aos-duration="300"
            class="hidden lg:block z-10"
          >
            <img src="/assets/img/landing_3.png" alt="" />
          </div>
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
        data-aos-duration="500"
        class="col-span-4 sm:col-span-3 md:col-span-2 flex flex-wrap content-center"
      >
        <div class="text-left px-4">
          <h2
            class="text-base text-yellow-500 font-semibold tracking-wide uppercase"
          >
            Management
          </h2>
          <p
            :class="isDark ? 'text-gray-200' : 'text-gray-800'"
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight  sm:text-4xl"
          >
            <span class="text-yellow-500"></span>myNibble App
          </p>
          <p
            :class="isDark ? 'text-gray-400' : 'text-gray-600'"
            class="mt-4 max-w-2xl text-base md:text-lg lg:mx-auto"
          >
            Gestisci il tuo abbonamento in autonomia. <br />
            <strong>myNibble App</strong> racchiude tutti i nostri principali
            servizi: ti permetterà di scaricare i documenti importanti, pagare
            le fatture e gestire tutte le tue richieste.
          </p>
          <div class="hidden sm:flex flex-col items-start my-8">
            <div
              class="flex-shrink flex flex-row items-center space-x-3 text-gray-200 px-4 py-2 border border-gray-200 bg-black bg-opacity-60 text-center text-lg rounded-xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <p class=" ">
                In arrivo a fine Estate!
              </p>
            </div>

            <!-- Da sbloccare quando l'app sarà conclusa -->
            <!--                                          
            <div class="grid grid-cols-2 my-8 flex-wrap">
              <div class="col-span-2 mb-2 lg:mb-0 lg:col-span-1">
                <img class="w-32 lg:w-40" src="/assets/img/aotas.svg" alt="" />
              </div>
              <div class="col-span-2 lg:col-span-1">
                <img class="w-32 lg:w-40" src="/assets/img/giogp.svg" alt="" />
              </div>
            </div>
            -->

            <p
              :class="isDark ? 'text-gray-400' : 'text-gray-600'"
              class="mt-8 max-w-2xl text-lg"
            >
              accedi ora all'<a
                href="https://my.nibble.it"
                class="font-semibold text-yellow-500 cursor-pointer"
                >Area Clienti</a
              >
              da Web
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sm:hidden mx-auto px-6 text-center pt-6 pb-2 flex flex-col items-center"
    >
      <div
        data-aos="fade-up"
        data-aos-duration="400"
        class="flex-shrink flex flex-row items-center space-x-3 text-gray-100 px-4 py-2 border border-gray-200 bg-black bg-opacity-60 text-center text-lg rounded-xl"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <p class=" ">
          In arrivo a fine Estate!
        </p>
      </div>
      <!-- <div class="grid grid-cols-2 my-8 flex-wrap">
        <div class="col-span-2 mb-2 lg:mb-0 lg:col-span-1">
          <img class="w-32 lg:w-40" src="/assets/img/aotas.svg" alt="" />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <img class="w-32 lg:w-40" src="/assets/img/giogp.svg" alt="" />
        </div>
      </div> -->
      <div data-aos="fade-up" data-aos-duration="400">
        <p
          :class="isDark ? 'text-gray-400' : 'text-gray-600'"
          class="mt-8 mb-2 max-w-2xl text-md lg:mx-auto"
        >
          accedi ora all'<a
            href="https://my.nibble.it"
            class="font-semibold text-yellow-500 cursor-pointer"
            >Area Clienti</a
          >
          da Web
        </p>
      </div>
      <div class="mt-8 mb-10 flex flex-col items-center ">
        <base-logo class="pr-4" style="filter: grayscale(1)"></base-logo>
        <p class="mt-1 text-yellow-400 font-bold" style="filter: grayscale(1)">
          myNibble
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "AppLandingSection",
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
