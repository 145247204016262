export default {
  modalStatus(state) {
    return state.modalStatus;
  },
  modalPage(state) {
    return state.modalPage;
  },
  modalData(state) {
    return state.modalData;
  },
};