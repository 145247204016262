<template>
    <div class="max-w-7xl mx-auto relative text-sm">
        <nav
            class="relative flex items-center justify-between h-10 lg:justify-start"
        >
            <div class="flex items-center ml-4 md:ml-10 pr-4 md:pr-4 space-x-6 md:space-x-8 flex-nowrap overflow-x-auto">
                <span class="whitespace-nowrap font-bold text-lg text-gray-900"
                    >Supporto</span
                >

                <router-link
                    to="/support/start"
                    class="whitespace-nowrap font-medium text-base text-gray-900"
                    >Assistenza</router-link
                >

                <router-link
                    to="/support/faq"
                    class="whitespace-nowrap font-medium text-base text-gray-900"
                    >FAQ</router-link
                >

                <router-link
                    to="/support/check-coverage"
                    class="whitespace-nowrap font-medium text-base text-gray-900"
                    >Verifica copertura</router-link
                >

                <router-link
                    to="/support/partnership"
                    class="whitespace-nowrap font-medium text-base text-gray-900"
                    >Lavora con noi</router-link
                >
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "SupportMenu",
    props: {
        isDark: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isHover: false,
        };
    },
    methods: {
        hoverSupportLink() {
            this.isHover = !this.isHover;
        },
    },
};
</script>

<style scoped>
a:active,
a.router-link-active {
    @apply text-indigo-600;
}
a:hover {
    @apply text-indigo-600;
}
</style>