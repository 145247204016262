<template>
  <div class="grid grid-cols-4 items-center border-b-2 border-gray-200 py-1">
    <div
      class="col-span-1 text-left sm:text-lg py-1 sm:ml-8 rounded-l-full"
      v-html="data.dx"
    ></div>
    <div class="col-span-2 text-center sm:text-lg py-1 flex flex-col items-center leading-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <slot></slot>
      </svg>
      <p v-html="data.title"></p>
    </div>
    <div
      class="col-span-1 text-right sm:text-lg py-1 sm:mr-8 rounded-r-full"
      v-html="data.sx"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ComparisonRow",
  props: ["data"],
};
</script>
