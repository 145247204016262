<template>
  <div class="max-w-7xl mx-auto py-5 sm:py-10 md:pb-40">
    <div class="mx-4 md:mx-6">
      <section-heading
        :alwaysCentered="true"
        :heading="head.heading"
        :title="head.title"
        :subtitle="head.subtitle"
      ></section-heading>
      <div class="grid md:grid-cols-2 md:gap-5">
        <div class="col-span-1">
          <card-additional-service
            v-if="!isVoip"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="0"
            data-aos-duration="600"
            :heading="card_voip.heading"
            :isLight="true"
            :headingColor="card_voip.headingColor"
            :title="card_voip.title"
            :link="card_voip.link"
            :paragraphs="card_voip.paragraphs"
            :button="card_voip.button"
            :bg="card_voip.bg"
            :image="card_voip.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isVoip"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_voipbox_100.heading"
            :headingColor="card_voipbox_100.headingColor"
            :title="card_voipbox_100.title"
            :link="card_voipbox_100.link"
            :paragraphs="card_voipbox_100.paragraphs"
            :button="card_voipbox_100.button"
            :bg="card_voipbox_100.bg"
            :image="card_voipbox_100.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isVoip"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_voipbox_1000.heading"
            :headingColor="card_voipbox_1000.headingColor"
            :title="card_voipbox_1000.title"
            :link="card_voipbox_1000.link"
            :paragraphs="card_voipbox_1000.paragraphs"
            :button="card_voipbox_1000.button"
            :bg="card_voipbox_1000.bg"
            :image="card_voipbox_1000.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isPrivate"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_ddns.heading"
            :headingColor="card_ddns.headingColor"
            :title="card_ddns.title"
            :link="card_ddns.link"
            :paragraphs="card_ddns.paragraphs"
            :button="card_ddns.button"
            :bg="card_ddns.bg"
            :image="card_ddns.image"
          ></card-additional-service>
        </div>
        <div class="col-span-1">
          <card-additional-service
            v-if="isVoip"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_voipflat.heading"
            :headingColor="card_voipflat.headingColor"
            :title="card_voipflat.title"
            :link="card_voipflat.link"
            :paragraphs="card_voipflat.paragraphs"
            :button="card_voipflat.button"
            :bg="card_voipflat.bg"
            :image="card_voipflat.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isVoip"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_voipcustom.heading"
            :headingColor="card_voipcustom.headingColor"
            :title="card_voipcustom.title"
            :link="card_voipcustom.link"
            :paragraphs="card_voipcustom.paragraphs"
            :button="card_voipcustom.button"
            :bg="card_voipcustom.bg"
            :image="card_voipcustom.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isPrivate"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
            data-aos-duration="600"
            :heading="card_ip.heading"
            :headingColor="card_ip.headingColor"
            :title="card_ip.title"
            :link="card_ip.link"
            :paragraphs="card_ip.paragraphs"
            :button="card_ip.button"
            :bg="card_ip.bg"
            :image="card_ip.image"
          ></card-additional-service>
          <card-additional-service
            v-if="isBusiness"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="200"
            data-aos-duration="600"
            :heading="card_pbx.heading"
            :headingColor="card_pbx.headingColor"
            :title="card_pbx.title"
            :link="card_pbx.link"
            :paragraphs="card_pbx.paragraphs"
            :button="card_pbx.button"
            :bg="card_pbx.bg"
            :image="card_pbx.image"
          ></card-additional-service>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardBgGradient from "./elements/CardBgGradient";
import CardAdditionalService from "./elements/CardAdditionalService";
export default {
  components: { CardAdditionalService },
  name: "AdditionalServicesSection",
  props: ["clientType"],
  computed: {
    isBusiness() {
      return this.clientType == "business" ? true : false;
    },
    isPrivate() {
      return this.clientType == "private" ? true : false;
    },
    isVoip() {
      return this.clientType == "voip" ? true : false;
    },
  },
  data() {
    return {
      head: {
        heading: {
          mode: true,
          text: "Servizi opzionali",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Arricchisci la tua offerta",
          color: "text-gray-100",
        },
        subtitle: {
          mode: true,
          text: "Ecco una lista di servizi accessori alle nostre linee.",
          color: "text-gray-400",
        },
      },
      card_voip: {
        heading: "Numero Fisso",
        headingColor: "text-purple-500",
        title: "LINEA VOIP RICARICABILE",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg xmlns="http://www.w3.org/2000/svg" class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" /></svg></span><span>Zero scatto alla risposta</span>',
          '<span><svg xmlns="http://www.w3.org/2000/svg" class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span><span>Tariffazione al secondo</span>',
          '<span class="hidden xs:flex "><svg xmlns="http://www.w3.org/2000/svg" class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" /></svg></span><span class="hidden xs:flex ">Possibilità di portare il numero dal vecchio operatore</span>',
          '<span class="hidden xs:flex "><svg xmlns="http://www.w3.org/2000/svg" class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" /></svg></span><span class="hidden xs:flex ">Richiedere una nuova numerazione geografica</span>',
        ],
        button: {
          isActive: false,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "Offerte VoIP",
          classes:
            "hover:from-purple-100 hover:to-purple-200 text-purple-900 from-purple-100 to-purple-300 hover:shadow-xl",
        },
        bg: "from-purple-200 to-purple-500 ",
        image: "/assets/undraw/working_remotely.svg",
      },
      card_ip: {
        heading: "raggiungi casa da fuori",
        headingColor: "text-blue-600",
        title: "IP Pubblico Statico",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg></span><span>Possibilità di accedere in remoto al tuo computer o ai dispositivi di domotica</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span><span>Ospitare un server locale nel computer di casa, ad esempio per giocare online</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg></span><span>Possono essere richiesti fino a 16 IP consecutivi</span><br><br>',
        ],
        button: {
          isActive: false,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "Offerte VoIP",
          classes:
            "hover:from-purple-100 hover:to-purple-200 text-purple-900 from-purple-100 to-purple-300 hover:shadow-xl",
        },
        bg: "from-blue-200 to-blue-500 ",
        image: "/assets/undraw/uploading.svg",
      },
      card_ddns: {
        heading: "raggiungi casa da fuori",
        headingColor: "text-green-500",
        title: "DDNS",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg></span><span>Possibilità di mantenere la flessibilità di un IP dinamico sfruttandone le potenzialità per raggiungere casa da fuori</br></br></span>',
        ],
        button: {
          isActive: false,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "Offerte VoIP",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-green-200 to-green-500 ",
        image: "/assets/undraw/domain_names.svg",
      },
      card_voipbox_1000: {
        heading: "Pacchetti con minuti inclusi",
        headingColor: "text-yellow-600",
        title: "VOIP BOX 1000",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span><span>1000 min al mese verso fissi e mobili</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg></span><span>20 Canali simultanei</span>',
        ],
        button: {
          isActive: true,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "25€ / mese",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-yellow-200 to-yellow-400 ",
        image: "/assets/undraw/time_management.svg",
      },
      card_voipbox_100: {
        heading: "Pacchetti con minuti inclusi",
        headingColor: "text-yellow-600",
        title: "VOIP BOX 100/100",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" /></svg></span><span>100 min al mese verso fissi</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg></span><span>100 min al mese verso mobili</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg></span><span>20 Canali simultanei</span>',
        ],
        button: {
          isActive: true,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "16€ / mese",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-yellow-200 to-yellow-400 ",
        image: "/assets/undraw/season_change.svg",
      },
      card_voipflat: {
        heading: "Pacchetti illimitati",
        headingColor: "text-yellow-600",
        title: "VOIP FLAT",
        link: "https://my.nibble.it",
        paragraphs: [
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span><span>Minuti illimitati verso fissi e mobili</span>',
          '<span><svg class="h-4 sm:h-6 w-4 sm:w-6 flex-shrink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg></span><span>1 Canale simultaneo</span>',
        ],
        button: {
          isActive: true,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "24€ / mese",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-yellow-200 to-yellow-400 ",
        image: "/assets/undraw/coding.svg",
      },
      card_voipcustom: {
        heading: "Soluzioni su misura",
        headingColor: "text-yellow-600",
        title: "Richiedi il tuo pacchetto personalizzato",
        link: "https://my.nibble.it",
        paragraphs: [
          "Nelle nostre offerte non hai trovato quello che fa per te?",
          "Ora puoi scegliere i servizi che desideri e creare il tuo pacchetto VoIP personalizzato",
        ],
        button: {
          isActive: false,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "24€ / mese",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-yellow-200 to-yellow-400 ",
        image: "/assets/undraw/customization.svg",
      },
      card_pbx: {
        heading: "Scalabilità e dinamicità",
        headingColor: "text-yellow-600",
        title: "Centralino in Cloud",
        link: "https://my.nibble.it",
        paragraphs: [
          "Dai alla tua azienda un'immagine professionale.",
          "Gestisci le chiamate aziendali direttamente dal tuo smartphone.",
        ],
        button: {
          isActive: false,
          modal: {
            isActive: false,
            page: "login",
          },
          text: "24€ / mese",
          classes:
            "hover:from-gray-100 hover:to-gray-200 text-gray-900 from-gray-100 to-gray-300 hover:shadow-xl",
        },
        bg: "from-yellow-200 to-yellow-400 ",
        image: "/assets/undraw/quitting_time.svg",
      },
    };
  },
};
</script>
