<template>
    <h1 class="text-gray-700 text-lg md:text-xl mt-4 mb-2">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: "TitleThree",
}
</script>