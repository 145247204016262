<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="relative transition duration-500 ease-in-out overflow-hidden bg-gray-100"
  >
    <div
      class="max-w-7xl mx-auto transition duration-500 ease-in-out bg-gray-100"
    >
      <div
        class="relative z-10 lg:max-w-2xl lg:w-full transition duration-500 ease-in-out pb-6 sm:pb-10 md:pb-12 lg:pb-14 xl:pb-2 bg-gray-100"
      >
        <desktop-menu></desktop-menu>

        <mobile-menu></mobile-menu>

        <extended-header :data="extention"></extended-header>
      </div>
      <div class="md:mx-10 mb-10">
        <vue-player-video
          src="/assets/videos/about.mp4"
          :autoplay="true"
          :loop="true"
          :controls="false"
        ></vue-player-video>
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenu from "@/components/menu/MobileMenu";
import DesktopMenu from "@/components/menu/DesktopMenu";
import ExtendedHeader from "@/ui/ExtendedHeader";
import VuePlayerVideo from "vue3-player-video";

export default {
  name: "AboutHeader",
  components: { DesktopMenu, MobileMenu, ExtendedHeader, VuePlayerVideo },
  data() {
    return {
      extention: {
        isDark: false,
        header: "",
        title: "Chi siamo",
        highlightTitle: "",
        description: "",
        buttonA: {
          mode: true,
          link: "#prices",
          text: "Scopri l'offerta",
        },
        buttonB: {
          mode: true,
          link: "/support/check-coverage",
          text: "Verifica copertura",
        },
      },
    };
  },
};
</script>
