export default {
  setModal(context, payload) {
    console.log('setModalStatus', payload);
    context.commit('initModal', { newstate: payload.newstate, page: payload.page, data: payload.data });
  },
  async contact(context, payload) {


    
    console.log(`${process.env}`);
    let url =
      `https://api.nibble.it/website/contact-us`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: payload.data.name,
        surname: payload.data.surname,
        phone: payload.data.phone,
        email: payload.data.email,
        message: payload.data.message,
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    console.log(responseData)
  },
}