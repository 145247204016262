<template>
    <div class="flex md:pb-4">
        <div class="flex-grow">
            <h1
                class="text-gray-900 font-bold text-3xl sm:text-2xl md:text-4xl lg:text-5xl w-5/6 md:w-full"
            >
                Verifica la copertura
            </h1>
        </div>
    </div>
    <div class="">
        <div class="flex flex-row justify-left my-4">
            <div class="flex-auto text-gray-700 text-lg">
                <p class="text-lg md:text-xl">
                    Compila questo modulo per richiedere la verifica della
                    copertura nella tua zona.
                </p>
                <p class="text-lg md:text-xl">
                    Verrai contattato al più presto da un nostro incaricato.
                </p>
            </div>
        </div>

        <form @submit.prevent="onSubmit">
            <div class="flex justify-center pt-8 mb-4">
                <div
                    class="flex flex-grow flex-row rounded-md bg-gray-50 p-5 md:p-8 text-gray-900 shadow-xl"
                >
                    <div class="flex-grow">
                        <input
                            type="text"
                            name=""
                            placeholder="Nome*"
                            v-model="cashback.its_data.name"
                            :class="{
                                'border-green-500':
                                    cashback.its_data.name != '',
                            }"
                            class=" border border-gray-400 rounded-md bg-transparent focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
                            id=""
                        />
                    </div>
                    <div class="flex-grow">
                        <input
                            type="text"
                            name=""
                            placeholder="Cognome*"
                            v-model="cashback.its_data.surname"
                            :class="{
                                'border-green-500':
                                    cashback.its_data.surname != '',
                            }"
                            class="flex border border-gray-400 rounded-md bg-transparent focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
                            id=""
                        />
                    </div>
                </div>
            </div>

            <div class="flex py-12">
                <label class="inline-flex items-center mt-3">
                    <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-gray-600"
                        checked
                    /><span class="ml-2 text-gray-700"
                        >Accetto i Termini e Condizioni d'uso
                    </span>
                </label>
            </div>

            <div class="flex py-4 w-full sm:w-1/3 md:1/4 lg:w-1/6">
                <submit-button :state="formState" type="submit"
                    >Invita</submit-button
                >
                <!-- <button class="p-4 rounded-md bg-yellow-500">Invita</button> -->
            </div>
        </form>

        <div class="h-2 md:h-48"></div>
    </div>
</template>

<script>
import SubmitButton from "../../ui/buttons/SubmitButton";

export default {
    name: "PlanModal",
    components: { SubmitButton },
    props: ["data"],
    data() {
        return {
            cashback: {
                your_data: {
                    name: "",
                    surname: "",
                    phone: "",
                    email: "",
                },
                its_data: {
                    name: "",
                    surname: "",
                    phone: "",
                },
            },
            formState: "ready",
            howToFindCodCliText: false,
        };
    },
    methods: {
        unableToFindCodCli() {
            this.howToFindCodCliText = !this.howToFindCodCliText;
        },
        onSubmit() {
            console.log("submitted");
            this.formState = "loading";
            setTimeout(() => {
                this.formState = "ending";
            }, 1000);
            setTimeout(() => {
                this.formState = "ready";
            }, 2000);
        },
    },
};
</script>