<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="relative transition duration-500 ease-in-out overflow-hidden bg-gray-900"
  >
    <div
      class="max-w-7xl mx-auto transition duration-500 ease-in-out bg-gray-900"
    >
      <div
        class="relative z-10 lg:max-w-3xl lg:w-full transition duration-500 ease-in-out pb-6 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 bg-gray-900"
      >
        <div>
          <svg
            class="hidden lg:block absolute -right-24 inset-y-0 h-full w-48 text-gray-900 z-50 "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>
        <div class="text-gray-900 opacity-60">
          <svg
            data-aos="fade-right"
            data-aos-delay="100"
            class="hidden lg:block absolute -right-40 inset-y-0 h-full w-48 "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>
        <desktop-menu :isDark="true"></desktop-menu>

        <mobile-menu></mobile-menu>
        <extended-header :data="extention"> </extended-header>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          class="h-56 w-full object-cover sm:h-64 md:h-96 lg:w-full lg:h-full "
          src="/assets/img/afrovintage.jpeg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenu from "@/components/menu/MobileMenu";
import DesktopMenu from "@/components/menu/DesktopMenu";
import ExtendedHeader from "@/ui/ExtendedHeader";

export default {
  name: "BusinessHeader",
  components: { DesktopMenu, MobileMenu, ExtendedHeader },
  data() {
    return {
      extention: {
        isDark: true,
        header: "SERVIZI VOCE",
        title: "Telefona utilizzando una connessione internet e",
        highlightTitle: " scopri il VoIP di nibble",
        description:
          "Ti offriamo una suite completa di soluzioni di telefonia cloud per aumentare la produttività a ogni livello aziendale.",
        buttonA: {
          mode: false,
          text: "Scopri l'offerta",
        },
        buttonB: {
          mode: false,
          text: "Scopri i vantaggi",
        },
      },
    };
  },
  computed: {
    page() {
      return this.$route.path;
    },
  },
  methods: {
    defineSubmenuColor(item) {
      if (this.page == item) {
        return "text-yellow-500 hover:text-yellow-500";
      } else return "text-gray-100 hover:text-yellow-500 ";
    },
    goToSecondary(item) {
      if (item == "home") this.$router.replace("/");
      else this.$router.push(item);
    },
  },
};
</script>

<style scoped>
.submenu:active {
  color: white !important;
}

.track {
  stroke: inherit;
  stroke-width: 4;
}

.round {
  stroke-linejoin: round;
}
@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
