<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
        class="transition duration-500 ease-in-out bg-gray-900"
    >
        <div
            class="max-w-7xl mx-auto transition duration-500 ease-in-out bg-gray-900"
        >
            <div
                class="z-10 lg:max-w-2xl lg:w-full transition duration-500 ease-in-out pb-6 bg-gray-900 h-92"
            >
                <desktop-menu :isDark="true"></desktop-menu>

                <mobile-menu></mobile-menu>
            </div>
        </div>
        <div class="z-10 bg-gray-300 shadow-inner">
            <legal-menu></legal-menu>
        </div>
    </div>
</template>

<script>
import MobileMenu from "../../components/menu/MobileMenu";
import DesktopMenu from "../../components/menu/DesktopMenu";
import LegalMenu from "../../components/menu/LegalMenu";


export default {
    name: "LegalHeader",
    components: { DesktopMenu, MobileMenu, LegalMenu },
};
</script>

