<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div class="max-w-7xl mx-auto py-8 sm:py-16 md:py-20">
        <div class="flex flex-col space-y-6 md:space-y-8 mx-4 md:mx-10">
          <title-one
            >Scopri se sei raggiunto da Nibble: verifica la copertura</title-one
          >
          <div
            class="flex flex-row space-x-6 pb-3 md:pb-0 text-sm sm:text-base md:text-lg"
          >
            <div class="flex flex-row space-x-2 items-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                  />
                </svg>
              </div>
              <div class="flex flex-col sm:flex-row">
                <strong class="mr-2">DOWNLOAD</strong>
                <span class="whitespace-nowrap"> fino a 1 Gp/s</span>
              </div>
            </div>
            <div class="flex flex-row space-x-2 items-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
                  />
                </svg>
              </div>
              <div class="flex flex-col sm:flex-row">
                <strong class="mr-2">UPLOAD</strong>
                <span class="whitespace-nowrap"> fino a 100 Mb/s</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative bg-gradient-to-b from-gray-600 to-gray-900 pb-12 px-4">
      <transition name="slide-top-blur-2">
        <div
          v-if="!isLoading"
          class="max-w-7xl mx-auto py-2 sm:py-4 md:py-6 bg-gray-100 rounded-xl shadow-xl relative -top-5 md:-top-10"
        >
          <div class="mx-4 md:mx-10">
            <check-coverage-form
              @loadResult="resultation"
            ></check-coverage-form>
          </div>
        </div>
      </transition>
      <transition name="slide-top-blur-4">
        <div
          v-show="showResults"
          class="max-w-7xl mx-auto py-2 my-24 sm:py-4 md:py-6 bg-gray-100 rounded-xl shadow-xl relative -top-10"
        >
          <div
            v-if="tech_showed.nothing"
            class="mx-10 flex flex-col space-y-2 items-center"
          >
            <img
              src="/assets/undraw/tree_swing.svg"
              alt=""
              class="w-1/3 mx-auto mb-10"
            />
            <p class="text-3xl">Peccato!</p>
            <p class="text-xl text-center">
              Attualmente la tua zona non risulta coperta dal servizio Nibble
            </p>
          </div>
          <div v-else class="mx-10 flex flex-col space-y-2 items-center">
            <img
              src="/assets/undraw/navigator.svg"
              alt=""
              class="w-1/3 mx-auto mb-10"
            />
            <p class="text-3xl">Complimenti!</p>

            <div v-if="tech_showed.fwa">
              <p class="text-xl text-center">
                Sei coperto dal servizio FWA (Fibra misto Radio) di Nibble
              </p>
            </div>
            <div v-if="tech_showed.ftth">
              <p class="text-xl text-center">
                Sei coperto dal servizio Fibra di Nibble
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CheckCoverageForm from "./../../components/forms/CheckCoverageForm";

export default {
  name: "CoveragePage",
  components: { CheckCoverageForm },
  data() {
    return {
      isLoading: true,
      bgCardImage: "/assets/img/aerial-map.png",
      cardImg: "/assets/img/illustration-map.svg",
      showResults: false,
      tech_showed: {
        fwa: false,
        ftth: false,
        nothing: false,
      },
    };
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    resetTechShowed() {
      this.tech_showed.fwa = false;
      this.tech_showed.ftth = false;
      this.tech_showed.nothing = false;
    },
    resultation(data) {
      console.log(data);
      this.resetTechShowed();
      if (data.action == "show") {
        this.showResults = true;
      } else {
        this.showResults = false;
      }

      if (data.tech_available.length == 0) {
        this.tech_showed.nothing = true;
      } else {
        data.tech_available.forEach((el) => {
          this.tech_showed[el] = true;
        });
      }
    },
  },
};
</script>

<style scoped>
/* slide top blur effect for blocks */
.slide-top-blur-2-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-3-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.25s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}
.slide-top-blur-4-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.5s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
