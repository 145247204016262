import { createStore } from 'vuex';

// import authModule from './modules/auth/index.js';
import guestModule from './modules/guest/index.js';

const store = createStore({
  modules: {
    // auth: authModule,
    guest: guestModule,
  }
});

export default store;