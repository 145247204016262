<template>
  <div
    :class="bg"
    class="overflow-hidden bg-gradient-to-br shadow-2xl rounded-xl p-2 mt-5 md:p-8"
  >
    <div class="square-container">
      <div class="square m-2 xs:m-4 sm:m-5">
        <div class="content md:pb-2">
          <h2
            :class="headingColor"
            class="sm:mt-2 text-sm sm:text-md lg:text-lg font-semibold tracking-wide uppercase"
          >
            {{ heading }}
          </h2>
          <h1
            :class="isLight ? 'text-gray-900' : 'text-gray-50'"
            class="my-2 sm:mt-4 sm:mb-10 lg:my-10 font-bold flex-auto text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold"
            v-html="title"
          ></h1>
          <div class="flex flex-row items-start">
            <div class="flex flex-col content-between z-20 lg:w-3/4">
              <p
                :class="isLight ? 'text-gray-900' : 'text-gray-300'"
                class="text-md sm:text-lg lg:text-xl mt-4 leading-4 sm:leading-5 flex flex-row items-start space-x-4"
                v-for="(paragraph, index) in paragraphs"
                :key="index"
                v-html="paragraph"
              ></p>

              <!-- price -->
              <div
                v-if="button.isActive"
                class="xs:mt-8 sm:mt-12 text-2xl sm:text-5xl backdrop-filter backdrop-blur-md py-4 pr-4 w-max rounded-full"
              >
                {{ button.text }}
              </div>
              <!-- button -->
              <!-- <div
                v-if="!button.isActive"
                class="flex space-x-3 xs:mt-8 sm:mt-12 xs:mb-2 sm:mb-4 text-sm font-medium"
              >
                <div class="flex-auto flex space-x-3">
                  <div class="rounded-md shadow-inner">
                    <a
                      href="javascript:void(0)"
                      :class="button.classes"
                      @click="checkModal(button)"
                      class="w-full flex items-center justify-center px-8 py-3 font-medium rounded-md bg-gradient-to-tr md:py-4 md:text-lg md:px-10"
                    >
                      {{ button.text }}
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="flex-shrink z-10 opacity-30">
              <img
                class="absolute bottom-0 right-0 w-3/4"
                :src="image"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAdditionalService",
  props: {
    isLight: {
      type: Boolean,
      default: true,
    },
    heading: String,
    headingColor: String,
    title: String,
    link: String,
    paragraphs: [],
    button: {},
    bg: String,
    image: String,
  },
  methods: {
    checkModal(button) {
      if (button.modal && button.modal.isActive == true) {
        this.openModal();
      } else {
        window.location.replace(this.link);
      }
    },
    openModal() {
      setTimeout(() => {
        this.$store.dispatch("setModal", {
          newstate: true,
          page: "cashback",
        });
      }, 200);
    },
  },
};
</script>

<style scoped>
.square-container {
  display: flex;
  flex-wrap: wrap;
}

.square {
  position: relative;
  flex-basis: calc(100% - 10px);
  box-sizing: border-box;
}

.square::before {
  content: "";
  display: block;
}
</style>
