<template>
  <button
    v-if="state == 'ready'"
    class="w-full flex px-8 py-3 rounded-md text-gray-100 bg-gray-700 hover:bg-gray-900 hover:text-gray-300 md:py-4 md:text-lg md:px-10"
  >
    <div class="flex-grow text-center"><slot></slot></div>
  </button>
  <button
    v-if="state == 'loading'"
    class="w-full flex px-8 py-3 rounded-md justify-center text-gray-100 bg-gray-700 hover:bg-gray-900 hover:text-gray-300 md:py-4 md:text-lg md:px-10"
  >
    <div class="smt-spinner-circle">
      <div class="smt-spinner"></div>
    </div>
  </button>

  <button
    v-if="state == 'ending'"
    class="w-full flex px-8 py-3 rounded-md justify-center text-gray-100 bg-gray-700 hover:bg-gray-900 hover:text-gray-300 md:py-4 md:text-lg md:px-10"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </button>
</template>

<script>
// The component provide 3 states:
// - Ready state: the button is ready to be pushed
// - Loading state: the button has been pushed and is now loading and waiting for a callback
// - Ending state: the button end the lifecycle and is going to return after 3s in ready state

export default {
  name: "SubmitButton",
  props: ["state"],
};
</script>

<style scoped>
.smt-spinner-circle {
  width: 28px;
  height: 28px;
  position: relative;
  border-radius: 50%;
}
.smt-spinner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border-right: 2px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  animation: rotate--spinner 1.2s infinite;
}

@keyframes rotate--spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
