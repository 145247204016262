<template>
  <div>
    <div class="relative overflow-hidden shadow-inner ">
      <features-section></features-section>
    </div>
    <div class="relative overflow-hidden bg-gray-900">
      <additional-services-section
        :clientType="'voip'"
      ></additional-services-section>
    </div>
  </div>
</template>

<script>
import FeaturesSection from "@/sections/voip/FeaturesSection";
import AdditionalServicesSection from "@/sections/AdditionalServicesSection";

export default {
  components: { FeaturesSection, AdditionalServicesSection },
};
</script>
