<template>
  <div
    class="relative transition duration-500 ease-in-out overflow-hidden bg-gray-100"
  >
    <div
      class="max-w-7xl mx-auto transition duration-500 ease-in-out bg-gray-100"
    >
      <div
        class="relative z-10 lg:max-w-2xl lg:w-full transition duration-500 ease-in-out pb-6 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 bg-gray-100"
      >
        <div>
          <svg
            class="hidden lg:block absolute -right-24 inset-y-0 h-full w-48 text-gray-100 z-50 "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>
        <div class="text-gray-100 opacity-60">
          <svg
            data-aos="fade-right"
            data-aos-delay="100"
            class="hidden lg:block absolute -right-40 inset-y-0 h-full w-48 "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>

        <desktop-menu></desktop-menu>

        <mobile-menu></mobile-menu>

        <extended-header :data="extention"></extended-header>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="h-56 w-full object-cover sm:h-64 md:h-96 lg:w-full lg:h-full "
        src="/assets/img/wifi-family.jpeg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import MobileMenu from "../../components/menu/MobileMenu";
import DesktopMenu from "../../components/menu/DesktopMenu";
import ExtendedHeader from "../../ui/ExtendedHeader";

export default {
  name: "PrivatesHeader",
  components: { DesktopMenu, MobileMenu, ExtendedHeader },
  data() {
    return {
      extention: {
        isDark: false,
        header:
          '<strong class="text-gray-800">nibble.</strong> Il tuo Internet Service Provider',
        title: "Naviga da casa. ",
        highlightTitle: "Veloce e senza limiti",
        description:
          "Scegli l'offerta su misura per te e naviga da subito senza limiti",
        buttonA: {
          mode: true,
          link: "#prices",
          text: "Scopri l'offerta",
        },
        buttonB: {
          mode: true,
          link: "/support/check-coverage",
          text: "Verifica copertura",
        },
      },
    };
  },
};
</script>

<style scoped>
@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
