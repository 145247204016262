<template>
  <div class="max-w-7xl mx-auto bg-gradient-to-b from-gray-900 via-gray-700 opacity-90 to-gray-100 rounded-b-3xl my-20" data-aos="fade-up" data-aos-duration="600" data-aos-delay="300">
    <div class="">
      <card-bg-gradient
        :isLight="fourthCard.isLight"
        :heading="fourthCard.heading"
        :headingColor="fourthCard.headingColor"
        :title="fourthCard.title"
        :link="fourthCard.link"
        :paragraphs="fourthCard.paragraphs"
        :button="fourthCard.button"
        :bg="fourthCard.bg"
        :image="fourthCard.image"
      ></card-bg-gradient>
    </div>
  </div>
</template>

<script>
// import CardBgImage from "./elements/CardBgImage.vue";
import CardBgGradient from "./elements/CardBgGradient";
export default {
  components: { 
    // CardBgImage, 
    CardBgGradient 
    },
  name: "ContactUsSection",
  data() {
    return {
      head: {
        heading: {
          mode: true,
          text: "Flow",
          color: "text-yellow-500",
        },
        title: {
          mode: true,
          text: "Come funziona?",
          color: "text-gray-100",
        },
        subtitle: {
          mode: true,
          text: "Ok, ora sono curioso. Quanto tempo ci vuole a mettere la linea Nibble?",
          color: "text-gray-400",
        },
      },
      firstCard: {
        heading: "Contattaci",
        headingColor: "text-yellow-500",
        title: "Chiama o scrivi una mail per una verifica di copertura.",
        paragraphs: [
          "Mettiti in contatto con noi come preferisci. <br />In due minuti saremo in grado di effettuare una verifica di copertura e dirti con certezza se copriamo la tua zona.",
          "Ed è senza impegno alcuno.",
        ],
        button: {
          isActive: true,
          text: "Contattaci",
        },
        bgImage: "/assets/img/contact.jpg",
      },
      secondCard: {
        heading: "Appuntamento",
        headingColor: "text-yellow-600",
        title: "A noi bastano due ore. Dicci tu quando.",
        paragraphs: [
          "In funzione della disponibilità dei nostri tecnici fissiamo un appuntamento per l'installazione. <br /> Mattina o pomeriggio in orario d'ufficio.",
          "Entro 2-7 giorni dalla chiamata.",
        ],
        button: {
          isActive: false,
          text: "Contattaci",
        },
        bg: "from-yellow-200 to-yellow-500",
        image: "/assets/img/schedule.svg",
      },
      thirdCard: {
        heading: "Installazione",
        headingColor: "text-blue-500",
        title:
          "I nostri tecnici non scendono a compromessi. </br>Sicurezza e professionalità.",
        paragraphs: [
          "Installiamo una piccola antenna esterna fornita in comodato d'uso e la orientiamo verso il nodo Nibble più vicino.",
          "E sei subito online.",
        ],
        button: {
          isActive: false,
          text: "Contattaci",
        },
        bgImage: "/assets/img/installation.png",
      },
      fourthCard: {
        isLight: false,
        heading: "Supporto commerciale",
        headingColor: "text-yellow-400",
        title: "Sei pronto per navigare?",
        paragraphs: [
          "Goditi il meglio che Internet ha da offrire.</br>Senza limiti di tempo e di traffico.",
        ],
        link: '/legal/contact-us',
        button: {
          isActive: true,
          classes: "from-yellow-200 to-yellow-400",
          text: "Contattaci",
        },
        bg: "from-transparent to-transparent",
        image: "/assets/undraw/version_control.svg",
      },
    };
  },
};
</script>
