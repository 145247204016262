<template>
  <div
    :class="alwaysCentered ? 'text-center' : 'text-left'"
    class="pt-10 pb-8 md:pt-14 md:pb-10 lg:pt-20 lg:pb-16 lg:text-center"
  >
    <h2
      data-aos="fade-up"
      data-aos-duration="400"
      v-if="heading.mode"
      v-html="heading.text"
      :class="isDark ? heading.color : heading.darkColor"
      class="text-base font-semibold tracking-wide uppercase"
    ></h2>
    <p
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="100"
      v-if="title.mode"
      v-html="title.text"
      :class="isDark ? title.color : title.darkColor"
      class="mt-10 text-3xl sm:text-4xl lg:text-6xl leading-8 font-extrabold tracking-tight"
    ></p>
    <p
      data-aos="fade-up"
      data-aos-duration="400"
      v-if="subtitle.mode"
      v-html="subtitle.text"
      :class="[
        isDark ? subtitle.color : subtitle.darkColor,
        alwaysCentered ? 'mx-auto' : 'lg:mx-auto',
      ]"
      class="mt-10 max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-5xl text-xl sm:text-2xl md:text-3xl lg:mx-auto"
    ></p>
  </div>
</template>

<script>
export default {
  name: "SectionHeading",
  props: {
    alwaysCentered: Boolean,
    heading: {},
    title: {},
    subtitle: {},
    isDark: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>
