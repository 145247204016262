<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div
        :class="{
          'py-2 sm:py-4 md:py-20': filter.text == '',
          'py-2 sm:py-4 md:py-10': filter.text != '',
        }"
        class="max-w-full sm:max-w-9xl md:max-w-7xl mx-auto transition-all duration-300"
      >
        <div class="flex flex-col text-center w-full px-2">
          <title-two class="hidden sm:block -mt-4"
            >Come possiamo aiutarti?</title-two
          >
          <input
            class="w-full sm:w-1/2 mx-auto sm:my-3 md:my-6 px-3 sm:px-5 py-2 text-md sm:text-sm md:text-xl rounded-full placeholder-gray-400 font-light outline-none"
            placeholder="Usa parole chiave per trovare risposta"
            type="text"
            v-model="filter.text"
            name=""
            id=""
          />
          <paragraph class="hidden sm:block">
            Puoi anche scorrere tra gli argomenti qui sotto per trovare quello
            che stai cercando.
          </paragraph>
        </div>
      </div>
    </div>
    <div class="max-w-7xl mx-auto py-4 sm:py-4 md:py-10 opacity-100">
      <div class="flex flex-col w-full mb-4 md:mb-8">
        <div
          class="flex flex-row mx-4 space-x-6 md:mx-10 md:space-x-8 border-t border-gray-400 flex-nowrap overflow-x-auto items-center"
        >
          <h1
            :class="{
              'border-yellow-500 text-yellow-500':
                filter.category == 'connectivity' && filter.text == '',
              'border-gray-300 text-gray-500': filter.text != '',
            }"
            class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
            @click="setCategory('connectivity')"
          >
            Connettività
          </h1>
          <h1
            :class="{
              'border-yellow-500 text-yellow-500':
                filter.category == 'telephony' && filter.text == '',
              'border-gray-300 text-gray-500': filter.text != '',
            }"
            class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
            @click="setCategory('telephony')"
          >
            Telefonia
          </h1>
          <h1
            :class="{
              'border-yellow-500 text-yellow-500':
                filter.category == 'administration' && filter.text == '',
              'border-gray-300 text-gray-500': filter.text != '',
            }"
            class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
            @click="setCategory('administration')"
          >
            Amministrazione
          </h1>
          <h1
            :class="{
              'border-yellow-500 text-yellow-500':
                filter.category == 'general-info' && filter.text == '',
              'border-gray-300 text-gray-500': filter.text != '',
            }"
            class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
            @click="setCategory('general-info')"
          >
            Info Generali
          </h1>
        </div>
        <collapse-transition :duration="150">
          <div
            class="flex flex-row items-center w-full gap-4 mx-4 md:mx-10 mt-6"
            v-if="filter.text != ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <h2 class="text-base">Hai cercato "{{ filter.text }}"</h2>
            <svg
              @click="filter.text = ''"
              xmlns="http://www.w3.org/2000/svg"
              class="flex-shrink text-right h-4 w-4 text-gray-500 hover:text-yellow-500 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </collapse-transition>
      </div>
      <div
        v-for="(faq, index) in filteredJson"
        :key="index"
        class="transition-all duration-300"
        :class="{
          'bg-gray-700 md:bg-transparent shadow-inner md:shadow-none':
            filter.showed == index,
        }"
      >
        <div
          class="mx-4 md:mx-10 py-1.5 md:py-3"
          :class="{
            'border-b border-gray-600 ':
              filter.showed != index && filter.showed != index + 1,
          }"
        >
          <div
            @click="toggleFAQ(index)"
            :class="{
              'text-yellow-500 py-2': filter.showed == index,
            }"
            class="flex flex-row items-start space-x-2 md:space-x-4 hover:text-yellow-500 transition-all duration-300 text-lg cursor-pointer"
          >
            <div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                v-if="filter.showed != index"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-yellow-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                v-if="filter.showed == index"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="flex">
              <p class="text-base leading-5 md:leading-6">
                {{ faq.question }}
              </p>
            </div>
          </div>
          <collapse-transition :duration="400">
            <div
              v-show="filter.showed == index"
              :class="{
                'text-gray-200': filter.showed == index,
              }"
              class="ml-8 md:mx-10 mt-2 mb-6 md:my-6 md:text-gray-900 transition-all duration-300"
            >
              <div
                class="py-1"
                v-for="(el, aIndex) in faq.answear"
                :key="aIndex"
              >
                <paragraph
                  :class="el.classes"
                  v-if="el.type == 'p'"
                  v-html="el.text"
                ></paragraph>
                <a
                  class="text-yellow-500"
                  v-if="el.type == 'a'"
                  :class="el.classes"
                  :href="el.link"
                  >{{ el.label }}</a
                >
                <img
                  :class="el.classes"
                  v-if="el.type == 'img'"
                  :src="el.url"
                />
              </div>
            </div>
          </collapse-transition>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import json from "@/json/faq.json";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "FAQPage",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      myJson: json,
      filter: {
        category: "connectivity",
        text: "",
        showed: -1,
      },
    };
  },
  computed: {
    filteredJson() {
      return this.myJson.filter((el) => {
        if (this.filter.text == "") {
          return el.type == this.filter.category ? true : false;
        } else {
          return (
            this.searchInTitle(el.question) ||
            this.searchInParagraphs(el.answear)
          );
        }
      });
    },
  },
  methods: {
    toggleFAQ(index) {
      this.filter.showed == index
        ? (this.filter.showed = -1)
        : (this.filter.showed = index);
    },
    setCategory(category) {
      this.filter.showed = -1;
      this.filter.category = category;
    },
    searchInTitle(title) {
      if (title.toUpperCase().includes(this.filter.text.toUpperCase()))
        return true;
      else return false;
    },
    searchInParagraphs(answear) {
      let r = false;
      answear.forEach((el) => {
        if (el.type == "p") {
          if (el.text.toUpperCase().includes(this.filter.text.toUpperCase()))
            r = true;
        }
      });
      return r;
    },
  },
};
</script>
