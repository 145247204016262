<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div class="max-w-7xl mx-auto py-8 sm:py-16 md:py-20">
        <div class="flex flex-col space-y-6 md:space-y-8 mx-4 md:mx-10">
          <title-one>Privacy Policy</title-one>
          <title-two class="uppercase">
            Dati di contatto del Responsabile della Protezione dei
            Dati:</title-two
          >
          <paragraph>
            <strong>Nibble</strong> considera la tutela dei dati un valore
            fondamentale e quotidianamente lavora per garantire la privacy e la
            riservatezza dei propri Clienti e degli interessati (quali ad es.
            fornitori, partner, visitatori, etc.). In adempimento al nuovo
            Regolamento Generale per la protezione dei dati UE 2016/679, Nibble
            ha nominato un Responsabile per la protezione dei dati (anche RPD o
            DPO) che gli interessati possono contattare, per tutte le questioni
            relative al trattamento dei loro dati personali e all'esercizio dei
            loro diritti, ai seguenti recapiti:
          </paragraph>
          <paragraph>
            <strong> Mail:</strong> dpo@nibble.it
            <br />
            <strong> Posta:</strong> Nibble S.r.l. - Responsabile protezione dei
            Dati - Via Manara Valgimigli, 74 - San Piero in Bagno (FC) 47021. In
            adeguamento a tale nuova normativa e al fine di illustrare con
            trasparenza quali dati raccogliamo e i motivi di tale raccolta
            nonché i tuoi diritti, abbiamo aggiornato il contenuto delle nostre
            Informative, consultabili sul sito in questa sezione.
          </paragraph>
          <!-- <div
                        class="flex flex-row space-x-6 pb-3 md:pb-0 text-sm sm:text-base md:text-lg"
                    >
                        <div class="flex flex-row space-x-2 items-center">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-8 w-8"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                                    />
                                </svg>
                            </div>
                            <div class="flex flex-col sm:flex-row">
                                <strong>DOWNLOAD</strong>
                                <span class="whitespace-nowrap">
                                    fino a 1 Gp/s</span
                                >
                            </div>
                        </div>
                        <div class="flex flex-row space-x-2 items-center">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-8 w-8"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
                                    />
                                </svg>
                            </div>
                            <div class="flex flex-col sm:flex-row">
                                <strong>UPLOAD</strong>
                                <span class="whitespace-nowrap">
                                    fino a 100 Mb/s</span
                                >
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="bg-gradient-to-b from-gray-600 to-gray-900 flex-grow py-3 px-4">
      <transition
        v-for="(doc, index) in privacy_docs"
        :key="index"
        :name="'slide-top-blur-' + (index + 2)"
      >
        <a
          v-if="!isLoading"
          target="_blank"
          :href="doc.dst_path"
          :download="doc.filename"
          @mouseover="hoverDocument(doc.dst_path)"
          @mouseleave="leaveDocument(doc.dst_path)"
          class="cursor-pointer hover:bg-gray-300 hover:text-gray-700 transition-all duration-400 max-w-7xl mx-auto py-2 sm:py-4 md:py-3 bg-gray-100 rounded-xl shadow-dark relative -top-5 md:-top-10 my-4 flex flex-row items-center"
        >
          <div class="px-4 md:px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="flex-grow pr-2 md:px-4 flex justify-between">
            <div class="flex flex-col space-y-2">
              <div
                :class="{
                  '': doc.isHover,
                }"
                class="text-xl transition-all duration-500"
              >
                {{ doc.name }}
              </div>
              <div
                :class="{
                  '': doc.isHover,
                }"
                class="transition-all duration-100 text-sm text-gray-600"
              >
                {{ doc.description }}
              </div>
            </div>
            <div
              :class="{
                'text-green-700 hover:text-green-900': doc.isHover,
              }"
              class="hidden md:text-sm text-gray-100 flex flex-col md:flex-row-reverse items-center"
            >
              <div class="px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div>Scarica subito</div>
            </div>
          </div>
        </a>
      </transition>
    </div>
  </div>
</template>

<script>
import docs from "@/json/documents.json";
export default {
  name: "PrivacyPolicyPage",
  data() {
    return {
      isLoading: true,
      docs: docs,
      served_file: "",
    };
  },
  computed: {
    privacy_docs() {
      return this.docs.filter((el) => {
        console.log(el);
        return el.category == "privacy";
      });
    },
  },
  mounted() {
    this.isLoading = !this.isLoading;
  },
  methods: {
    hoverDocument(filepath) {
      console.log("hover");
      this.privacy_docs
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = true;
        });
      return;
    },
    leaveDocument(filepath) {
      this.privacy_docs
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = false;
        });
      return;
    },
  },
};
</script>

<style scoped>
/* slide top blur effect for blocks */
.slide-top-blur-2-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-3-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.25s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}
.slide-top-blur-4-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.5s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
