<template>
  <div>
    <div class="relative z-0 overflow-hidden shadow-inner">
      <div class="bg-gray-100">
        <div class="max-w-7xl mx-auto">
          <div class="flex flex-col space-y-6 md:space-y-8 mx-4 md:mx-10 pb-20">
            <p class="text-xl text-gray-900">
              <strong>Nibble</strong> nasce nel 2009 con l’idea di abbattere il
              divario digitale <i>(digital divide)</i>. Attraverso la nostra
              <strong>rete proprietaria</strong>, offriamo servizi di
              connettività internet a banda ultra larga raggiungendo sia aree
              urbane che periferie anche sprovvedute di linee fisse ed accessi
              in fibra ottica.
            </p>
            <p class="text-xl text-gray-900">
              La <strong>rete</strong> radio, completamente
              <strong>di proprietà</strong>, interfacciata a dorsali in fibra
              ottica e ponti radio licenziati, permettono prestazioni elevate,
              bassa latenza così da offrire soluzioni internet e voce di
              altissima qualità.
            </p>

            <div class="flex flex-row space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                />
              </svg>
              <h1 class="text-3xl text-gray-700">Integrazione</h1>
            </div>

            <p class="text-xl text-gray-900">
              La nostra infrastruttura termina nei principali datacenter
              Italiani così da avere la possibilità di interconnessione diretta
              con i più importanti cloud providers, garantendo sicurezza e
              velocità per l’accesso ai servizi maggiormente utilizzati (Google,
              Sky, Facebook, Instagram…)
            </p>

            <div class="flex flex-row space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
              <h1 class="text-3xl text-gray-700">Velocità</h1>
            </div>
            <p class="text-xl text-gray-900">
              La rete completamente proprietaria, con oltre 50 PoP (point of
              presence), estesa su due Regioni Italiane, ci permette di
              assicurare tempi rapidi in caso di guasti e allo stesso tempo
              avere il pieno controllo affinché si garantisca bassa latenza con
              velocità stabile per riuscire a garantire servizi Cloud e Voce.
            </p>

            <div class="flex flex-row space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                />
              </svg>
              <h1 class="text-3xl text-gray-700">Scalabilità</h1>
            </div>

            <p class="text-xl text-gray-900">
              Il completo monopolio dell’infrastruttura non ci limita ad erogare
              servizi standard, ma ci consente di offrire servizi anche
              personalizzati in base alle esigenze del cliente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>
