<template>
  <div
    class="z-50 fixed w-full h-full sm:pt-8 sm:px-24 top-0 mx-auto bg-gray-900 bg-opacity-70 overflow-y-scroll"
  >
    <transition name="slideBottom">
      <div v-if="modal">
        <div
          class="absolute z-50 text-gray-400 rounded-b-xl hover:text-gray-50 top-0 right-0 h-32 w-32 pt-4 pr-4 cursor-pointer"
          @click="closeModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-8 sm:w-14 float-right shadow-xl"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div
          v-click-outside="closeModal"
          class="z-50 max-w-screen-xl mx-auto h-full"
          style="backdrop-filter: blur(20px)"
        >
          <div
            class="bg-gray-200 sm:rounded-t-2xl shadow-lg px-4 sm:px-8 lg:px-16 py-4 sm:py-8 lg:py-12 shadow-sm"
          >
            <business-plan-modal
              @closeModal="closeModal()"
              :data="modalData"
              v-if="modalPage == 'business-plan'"
            ></business-plan-modal>
            <privates-plan-modal
              @closeModal="closeModal()"
              :data="modalData"
              v-if="modalPage == 'privates-plan'"
            ></privates-plan-modal>
            <cashback-modal
              :data="modalData"
              v-if="modalPage == 'cashback'"
            ></cashback-modal>
            <coverage-modal
              :data="modalData"
              v-if="modalPage == 'coverage'"
            ></coverage-modal>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PrivatesPlanModal from "./../components/modals/PrivatesPlanModal";
import BusinessPlanModal from "./../components/modals/BusinessPlanModal";
import CashbackModal from "./../components/modals/CashbackModal";
import CoverageModal from "./../components/modals/CoverageModal";

export default {
  components: {
    PrivatesPlanModal,
    BusinessPlanModal,
    CashbackModal,
    CoverageModal,
  },
  computed: {
    modal() {
      return this.$store.getters["modalStatus"];
    },
    modalPage() {
      return this.$store.getters["modalPage"];
    },
    modalData() {
      return this.$store.getters["modalData"];
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("setModal", { newstate: false });
    },
  },
};
</script>
