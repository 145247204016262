<template>
  <div
    class="bg-no-repeat bg-top bg-cover"
    :style="{ backgroundImage: `url(${bgImage})` }"
  >
    <div class="bg-gray-900 bg-opacity-70 min-h-screen">
      <div class="max-w-7xl mx-auto px-3 py-10 sm:py-14 md:py-20 opacity-100">
        <div
          class="flex flex-col items-center w-full h-92 text-white text-center"
        >
          <!-- 1. Title -->
          <div class="flex-grow">
            <h2 class="text-lg md:text-xl">Benvenuto nel supporto Nibble</h2>
            <transition name="slideBottom">
              <h1 v-if="flow != ''" class="text-2xl md:text-4xl font-bold">
                {{ firstCaption }}
              </h1>
            </transition>
          </div>

          <!-- 1. Choise -->
          <div class="flex-grow py-4 sm:py-12">
            <div
              :class="{
                'grid-cols-1 md:grid-cols-2 gap-4': flow == 'start',
                'flex items-center content-center': flow != 'start',
              }"
              class="grid"
            >
              <transition name="slideLeft">
                <div
                  v-show="
                    isLoading && (flow == 'start' || selections.first == 'yes')
                  "
                  @click="selectFirst('yes')"
                  :class="{
                    'bg-indigo-400 hover:bg-indigo-400 border-indigo-400':
                      selections.first == 'yes',
                  }"
                  style="backdrop-filter: blur(1px)"
                  class="flex items-center bg-white bg-opacity-50 border-2 border-transparent hover:bg-opacity-50 cursor-pointer hover:bg-gray-300 rounded-lg px-3 text-lg text-gray-900 w-64"
                >
                  <div class="flex pr-1 py-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-12 w-12 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                  <div class="flex text-left leading-6">
                    <p>
                      Si, sono un cliente
                      <strong>Nibble</strong>
                    </p>
                  </div>
                </div>
              </transition>
              <transition name="slideLeft">
                <div
                  v-show="
                    isLoading && (flow == 'start' || selections.first == 'no')
                  "
                  @click="selectFirst('no')"
                  style="backdrop-filter: blur(1px)"
                  :class="{
                    'bg-indigo-400 hover:bg-indigo-400 border-indigo-400':
                      selections.first == 'no',
                  }"
                  class="flex items-center bg-white bg-opacity-50 border-2 border-transparent hover:bg-opacity-50 cursor-pointer hover:bg-gray-300 rounded-lg px-3 py-1 text-lg text-gray-900 w-64"
                >
                  <div class="flex pr-1 py-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-12 w-12 text-red-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                  <div class="flex text-left leading-6">
                    <p>
                      Non sono ancora un cliente
                      <strong>Nibble</strong>
                    </p>
                  </div>
                </div>
              </transition>
            </div>
            <transition name="slideBottom">
              <div v-if="flow != 'start'" class="flex-grow p-4">
                <p @click="resetForm()" class="cursor-pointer">
                  (Cambia)
                </p>
              </div>
            </transition>
          </div>

          <!-- 1. Button -->
          <transition name="slideBottom">
            <div v-if="selectionFirst" class="flex-grow py-3 sm:py-12">
              <div class="grid">
                <base-button
                  @click="firstForward()"
                  class="shadow-md hover:shadow-xl"
                >
                  <double-arrow class="text-gray-50">Avanti</double-arrow>
                </base-button>
              </div>
            </div>
          </transition>

          <!--
                        1.A = SI, è utente nibble
                        1.B = NO, non è utente nibble -> 2
                    //-->

          <!-- 1.A Response   -->
          <div
            v-show="flow == 'second' && selections.first == 'yes'"
            class="flex-grow max-w-4xl"
          >
            <div
              :class="{
                'grid-cols-1 space-y-4 md:grid-cols-2 md:space-y-0':
                  flow == 'second',
              }"
              class="grid"
            >
              <transition name="slideBottom">
                <router-link to="/support/faq" class="w-full">
                  <div
                    v-show="isLoading && flow == 'second'"
                    class="rounded-md md:rounded-r-none flex flex-col items-baseline bg-gray-100 bg-opacity-75 hover:bg-opacity-50 cursor-pointer hover:bg-indigo-100 border-r border-gray-500 p-4 py-6 md:p-10 mx-4 md:mx-0 text-xl text-gray-800 hover:text-gray-900"
                    style="backdrop-filter: blur(1px)"
                  >
                    <div
                      class="flex w-full flex-row h-full items-top text-center"
                    >
                      <div class="w-full flex flex-col items-center">
                        <div class="mb-8">
                          <p class="text-3xl font-bold uppercase">
                            faq
                          </p>
                        </div>
                        <div>
                          <p class="text-lg leading-6 text-gray-700">
                            Consulta le FAQ e trova risposte alle
                            <span class="font-bold">domande frequenti</span>
                            <br />
                          </p>
                          <ul
                            class="list-disc text-base leading-5 mt-4 mx-4 md:mx-16 text-left"
                          >
                            <li>Configurazioni</li>
                            <li>Pagamenti</li>
                            <li>Documentazione</li>
                          </ul>
                          <router-link to="/support/faq">
                            <p class="pt-6 uppercase text-indigo-600">
                              Consulta →
                            </p>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </transition>
              <transition name="slideBottom">
                <a target="_blank" href="https://my.nibble.it">
                  <div
                    v-show="isLoading && flow == 'second'"
                    class="rounded-md md:rounded-l-none flex flex-col items-baseline bg-gray-100 bg-opacity-75 hover:bg-opacity-50 cursor-pointer hover:bg-indigo-100 border-r border-gray-500 p-4 py-6 md:p-10 mx-4 md:mx-0 text-xl text-gray-800 hover:text-gray-900"
                    style="backdrop-filter: blur(1px)"
                  >
                    <div
                      class="flex w-full flex-row h-full items-center text-center"
                    >
                      <div class="flex flex-col justify-between">
                        <div class="mb-8">
                          <p class="text-3xl font-bold uppercase">
                            Area riservata
                          </p>
                        </div>
                        <div>
                          <p class="text-lg leading-6 text-gray-700">
                            Accedi alla tua area personale e ricevi
                            <span class="font-bold">assistenza dedicata</span>
                            <br />
                          </p>
                          <ul
                            class="list-disc text-base leading-5 mt-4 mx-4 md:mx-16 text-left"
                          >
                            <li>
                              Supporto tecnico
                            </li>
                            <li>
                              Richieste commerciali
                            </li>
                            <li>
                              Pratiche amministrative
                            </li>
                          </ul>
                          <p class="pt-6 uppercase text-indigo-600">
                            Accedi →
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </transition>
            </div>
          </div>

          <!-- 2. Title -->
          <div
            id="secondStep"
            class="flex-grow"
            v-show="
              (flow == 'second' || flow == 'third') && selections.first == 'no'
            "
          >
            <transition name="slideBottom">
              <h1
                v-if="flow == 'second' || flow == 'third'"
                class="text-2xl md:text-4xl font-bold"
              >
                {{ secondCaption }}
              </h1>
            </transition>
          </div>

          <!-- 2. Choise -->
          <div
            v-show="
              (flow == 'second' || flow == 'third') && selections.first == 'no'
            "
            class="flex-grow py-4 md:py-12"
          >
            <div
              :class="{
                'grid-cols-2 gap-8': flow == 'second',
                'flex items-center content-center': flow != 'second',
              }"
              class="grid"
            >
              <transition name="slideLeft">
                <div
                  v-show="
                    isLoading &&
                      (flow == 'second' || selections.second == 'privates')
                  "
                  :class="{
                    'bg-indigo-400 hover:bg-indigo-400 border-indigo-400':
                      selections.second == 'privates',
                  }"
                  @click="selectSecond('privates')"
                  class="flex flex-col items-center bg-white bg-opacity-75 cursor-pointer border-2 border-transparent hover:bg-opacity-75 hover:bg-gray-300 rounded-lg p-2 md:p-4 w-28 md:w-40 text-base sm:text-lg text-gray-900 "
                >
                  <div class="flex py-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-14 text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  </div>
                  <div class="flex text-left uppercase font-bold leading-6">
                    <p>Privato</p>
                  </div>
                </div>
              </transition>
              <transition name="slideLeft">
                <div
                  v-show="
                    isLoading &&
                      (flow == 'second' || selections.second == 'business')
                  "
                  :class="{
                    'bg-indigo-400 hover:bg-indigo-400 border-indigo-400':
                      selections.second == 'business',
                    'scale-90': flow == 'third',
                  }"
                  @click="selectSecond('business')"
                  class="flex flex-col items-center bg-white bg-opacity-75 cursor-pointer border-2 border-transparent hover:bg-opacity-75 hover:bg-gray-300 rounded-lg text-base sm:text-lg text-gray-900 p-2 md:p-4 w-28 md:w-40 transform transition duration-500"
                >
                  <div class="flex pr-1 py-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-14 text-gray-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                  </div>
                  <div class="flex text-left uppercase font-bold leading-6">
                    <p>Business</p>
                  </div>
                </div>
              </transition>
            </div>
            <transition name="slideBottom">
              <div v-if="flow == 'third'" class="flex-grow p-4">
                <p @click="resetForm2()" class="cursor-pointer">
                  (Cambia)
                </p>
              </div>
            </transition>
          </div>

          <!-- 2. Button -->
          <transition name="slideBottom">
            <div v-if="selectionSecond" class="flex-grow py-0 sm:py-12">
              <div class="grid">
                <base-button
                  @click="secondForward()"
                  class="shadow-md hover:shadow-xl"
                >
                  <double-arrow class="text-gray-50">Avanti</double-arrow>
                </base-button>
              </div>
            </div>
          </transition>

          <!-- 2. Response -->
          <div
            id="thirdStep"
            v-show="flow == 'third'"
            class="w-full max-w-5xl py-0 sm:py-12"
          >
            <div class="grid">
              <transition name="slideBottom">
                <div
                  v-show="isLoading && flow == 'third'"
                  class="text-xl text-gray-800"
                >
                  <div
                    class="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 flex-grow md:items-start text-center"
                  >
                    <div class="flex flex-col space-y-4 flex-grow">
                      <div
                        style="backdrop-filter: blur(1px);"
                        class="flex-grow w-full flex-col p-3 md:p-6 rounded-md bg-gray-100 bg-opacity-75"
                      >
                        <p class="text-lg md:text-xl">
                          Scopri le nostre
                          <strong>offerte</strong>
                          dedicate
                          {{ secondResponse }}.
                        </p>
                        <router-link :to="offersLink">
                          <p class="pt-2 text-lg uppercase text-indigo-600">
                            Scopri →
                          </p>
                        </router-link>
                      </div>
                      <div
                        style="backdrop-filter: blur(1px);"
                        class="flex-grow items-center w-full flex-col p-3 md:p-6 rounded-md bg-gray-100 bg-opacity-75"
                      >
                        <p class="text-lg md:text-xl">
                          Contatta direttamente un nostro consulente commerciale
                        </p>
                        <div
                          class="flex flex-col py-3 leading-8 text-base md:text-lg"
                        >
                          <div class="flex-grow flex flex-col items-center">
                            <div
                              class="flex-grow flex flex-row space-x-4 items-center"
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                                  />
                                  <path
                                    d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                  />
                                </svg>
                              </div>
                              <div>
                                <strong>info@nibble.it</strong>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow flex flex-col items-center">
                            <div
                              class="flex-grow flex flex-row space-x-4 items-center"
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                                  />
                                </svg>
                              </div>
                              <div>
                                <strong>+39 0543 918593 </strong>
                                selezione 3
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style="backdrop-filter: blur(1px)"
                      class="rounded-md bg-gray-100 bg-opacity-75 flex-grow flex-col items-center px-3 py-6 md:p-6 border-l border-gray-800"
                    >
                      <div>
                        <p class="text-lg md:text-xl">
                          Compila il form per essere ricontattato da un nostro
                          consulente
                        </p>
                        <contact-form class="md:px-4"></contact-form>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoubleArrow from "@/ui/buttons/DoubleArrow";
import ContactForm from "@/components/forms/ContactForm";

export default {
  components: { DoubleArrow, ContactForm },
  name: "FrontofficeSection",
  data() {
    return {
      bgImage: "/assets/img/support-center.jpeg",
      isLoading: false,
      selectionFirst: false,
      selectionSecond: false,
      selections: {
        first: "",
        second: "",
      },
      flow: "",
      firstCaption: "Sei già un cliente Nibble?",
      secondCaption: "Che tipo di utente sei?",
      thirdCaption: "Di cosa hai bisogno?",
    };
  },
  computed: {
    secondResponse() {
      return this.selections.second == "business"
        ? "al Business"
        : "ai Privati";
    },
    offersLink() {
      return "/" + this.selections.second + "#prices";
    }
  },
  mounted() {
    this.isLoading = true;
    this.flow = "start";
  },
  methods: {
    selectFirst(selection) {
      if (this.flow == "start") {
        if (this.selections.first == selection) {
          this.selectionFirst = !this.selectionFirst;
          this.resetForm();
        } else {
          this.selections.first = selection;
          if (!this.selectionFirst) this.selectionFirst = !this.selectionFirst;
        }
      }
    },
    selectSecond(selection) {
      if (this.flow == "second") {
        if (this.selections.second == selection) {
          this.selectionSecond = !this.selectionSecond;
          this.resetForm2();
        } else {
          this.selections.second = selection;
          if (!this.selectionSecond)
            this.selectionSecond = !this.selectionSecond;
        }
      }
    },
    firstForward() {
      this.flow = "second";
      this.selectionFirst = !this.selectionFirst;
      this.scrollToEnd("secondStep");
    },
    secondForward() {
      this.flow = "third";
      this.selectionSecond = !this.selectionSecond;
      this.scrollToEnd("thirdStep");
    },
    resetForm() {
      this.resetForm2();
      this.flow = "start";
      this.selections.first = "";
    },
    resetForm2() {
      this.flow = "second";
      this.selections.second = "";
      this.selectionSecond = false;
    },
    scrollToEnd(tagId) {
      let container = this.$el.querySelector(`#${tagId}`);
      container.scrollTop = container.scrollHeight;
    },
  },
};
</script>

<style scoped>
.slideBottom-enter-from {
  transform: translateY(40%);
  opacity: 0;
}

.slideBottom-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slideBottom-enter-active {
  transition: all 0.3s ease-out;
}

.slideBottom-leave-active {
  transition: all 0.2s ease-out;
}

.slideBottom-enter-to .slideBottom-leave-from {
  transform: translateY(100%);
  opacity: 1;
}

.slideLeft-enter-from {
  transform: translateX(3000px);
}

.slideLeft-leave-to {
  transform: translateX(3000px);
}

.slideLeft-enter-active {
  transition: all 0.3s ease-out;
}

.slideLeft-leave-active {
  transition: all 0.5s ease-out;
}

.slideLeft-enter-to .slideLeft-leave-from {
  transform: translateX(100%);
}
</style>
