<template>
  <div
    :class="bg"
    class="overflow-hidden bg-gradient-to-br shadow-2xl rounded-xl p-2 mt-5 md:p-8"
  >
    <div class="grid grid-cols-6 px-3 sm:px-5 py-3 flex-nowrap content-center">
      <div class="col-span-6 md:col-span-4 lg:col-span-3 md:pr-4 z-20">
        <div class="md:pb-2">
          <h2
            :class="headingColor"
            class="text-lg font-semibold tracking-wide uppercase"
          >
            {{ heading }}
          </h2>
          <h1
            :class="isLight ? 'text-gray-900' : 'text-gray-50'"
            class="my-10 font-bold flex-auto text-3xl xs:text-4xl font-semibold"
            v-html="title"
          ></h1>
        </div>
        <p
          :class="isLight ? 'text-gray-600' : 'text-gray-300'"
          class="text-xl mt-4"
          v-for="(paragraph, index) in paragraphs"
          :key="index"
          v-html="paragraph"
        ></p>
        <div
          v-if="button.isActive"
          class="flex space-x-3 mt-10 mb-4 text-sm font-medium"
        >
          <div class="flex-auto flex space-x-3">
            <div class="rounded-md shadow-inner">
              <router-link
                :to="link"
                :class="button.classes"
                class="w-full flex items-center justify-center px-8 py-3 font-medium rounded-md bg-gradient-to-tr md:py-4 md:text-lg md:px-10"
              >
                {{ button.text }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative md:block col-start-2 col-span-4 mt-8 md:col-span-2 lg:col-span-3 -mr-16 lg:mr-0 lg:pl-2 lg:pr-8 z-10"
      >
        <img
          class="absolute md:block -right-28 md:right-0 bottom-0 opacity-40 md:opacity-100 md:max-h-full"
          :src="image"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBgGradient",
  props: {
    isLight: {
      type: Boolean,
      default: true,
    },
    heading: String,
    headingColor: String,
    title: String,
    link: String,
    paragraphs: [],
    button: {},
    bg: String,
    image: String,
  },
  methods: {
    checkModal(button) {
      if (button.modal && button.modal.isActive == true) {
        this.openModal();
      } else {
        window.location.replace(this.link);
      }
    },
    openModal() {
      setTimeout(() => {
        this.$store.dispatch("setModal", {
          newstate: true,
          page: "cashback",
        });
      }, 200);
    },
  },
};
</script>
