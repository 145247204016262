import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import router from './router.js';
import store from './store/index.js';

import GlobalDirectives from "./plugins/globalDirectives.js";

import App from './App';

// Global Cmp
import SectionHeading from './ui/SectionHeading';
import BaseButton from './ui/buttons/BaseButton';
// - Paragraphs
import TitleOne from './ui/paragraphs/TitleOne';
import TitleTwo from './ui/paragraphs/TitleTwo';
import TitleThree from './ui/paragraphs/TitleThree';
import Paragraph from './ui/paragraphs/Paragraph';
import BaseLogo from './ui/BaseLogo';

const app = createApp(App)

app.use(router);
app.use(store);
app.use(VueReCaptcha, { siteKey: '6LfZLr8bAAAAAKypCq_mvHXTMUbLGVYwclX3wOmO' })

app.use(GlobalDirectives);

app.component('section-heading', SectionHeading);
app.component('base-button', BaseButton);
app.component('title-one', TitleOne);
app.component('title-two', TitleTwo);
app.component('title-three', TitleThree);
app.component('paragraph', Paragraph);
app.component('base-logo', BaseLogo);

app.mount('#app')

import 'aos/dist/aos.css'
import './index.css';
import './assets/tailwind.css';