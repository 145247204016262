<template>
  <form @submit.prevent="onSubmit" class="text-lg">
    <div class="flex flex-col gap-4 items-center pt-4 mb-4">
      <div class="flex-grow flex flex-col md:flex-row gap-4 w-full">
        <input
          type="text"
          name=""
          placeholder="Nome*"
          v-model="formdata.name"
          :class="{
            'border-green-500': formdata.name != '',
          }"
          class="w-full md:w-1/2 border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
        />
        <input
          type="text"
          name=""
          placeholder="Cognome*"
          v-model="formdata.surname"
          :class="{
            'border-green-500': formdata.surname != '',
          }"
          class="w-full md:w-1/2 border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
        />
      </div>
      <div class="flex-grow flex flex-col md:flex-row gap-4 w-full">
        <input
          type="phone"
          name=""
          placeholder="Cellulare*"
          v-model="formdata.phone"
          :class="{
            'border-green-500': formdata.phone != '',
          }"
          class="w-full md:w-1/2 border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
        />
        <input
          type="email"
          name=""
          placeholder="Email*"
          v-model="formdata.email"
          :class="{
            'border-green-500': formdata.email != '',
          }"
          class="w-full md:w-1/2 border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
        />
      </div>
      <div class="flex-grow w-full">
        <textarea
          v-model="formdata.message"
          placeholder="Messaggio"
          name=""
          id=""
          cols="30"
          rows="3"
          class="w-full border border-gray-400 rounded-md bg-white focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-700 placeholder-opacity-75 px-4 py-3"
        ></textarea>
      </div>
    </div>

    <div class="flex py-3">
      <label class="flex flex-row items-center text-left">
        <input
          type="checkbox"
          v-model="terms"
          class="form-checkbox h-5 w-5 text-gray-600"
          checked
        /><span class="ml-2 text-gray-700 text-sm md:text-base"
          >Accetto i Termini e Condizioni d'uso
        </span>
      </label>
    </div>

    <div class="py-4 w-full md:w-1/2 mx-auto">
      <div v-if="terms" @click="recaptcha">
        <submit-button :state="formState">Invia</submit-button>
      </div>

      <div v-else class="text-center">
        Accetta i termini per proseguire
      </div>
    </div>
    <div v-if="showAlert && !formStatus">
      Per procedere è necessario compilare tutti i campi
    </div>
  </form>
</template>

<script>
import SubmitButton from "@/ui/buttons/SubmitButton";

export default {
  name: "ReverseContactForm",
  components: { SubmitButton },
  data() {
    return {
      formdata: {
        name: "",
        surname: "",
        phone: "",
        email: "",
        message: "",
      },
      terms: false,
      showAlert: false,
      formState: "ready",
    };
  },
  computed: {
    formStatus() {
      if (
        this.formdata.name != "" &&
        this.formdata.surname != "" &&
        this.formdata.phone != "" &&
        this.formdata.email != "" &&
        this.formdata.message != ""
      )
        return true;
      else return false;
    },
  },
  methods: {
    async recaptcha() {
      this.showAlert = false;
      if (this.formState == "ready" && this.formStatus) {
        this.formState = "loading";
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha("login");

        await this.$store.dispatch("contact", {
          data: this.formdata,
          token: token,
        });
        this.formState = "ending";
      } else {
        if (!this.formStatus) this.showAlert = true;
      }
    },
  },
};
</script>
