<template>
    <h1 class="text-gray-800 text-xl md:text-2xl mt-7 mb-5">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: "TitleTwo",
}
</script>