<template>
  <div class="min-h-screen">
    <div class="flex md:pb-4">
      <div class="flex-grow">
        <title-one class="w-5/6 md:w-full -mt-0">Porta un amico in Nibble</title-one>
      </div>
    </div>
    <div class="">
      <div class="flex flex-row justify-left">
        <div class="flex-auto">
          <title-two>
            Avere amici conviene!
            <strong>Portali in Nibble!</strong>
            <br />
            Per entrambi un mese di navigazione gratis.
          </title-two>
        </div>
      </div>
      <div class="flex flex-col-reverse md:flex-row justify-left">
        <div class="flex-auto">
          <title-two class="font-bold">
            <span class="text-yellow-500">Chi può</span> presentare i suoi
            amici?
          </title-two>
          <title-three>
            Tutti coloro che hanno un servizio Nibble attivo.
          </title-three>

          <title-two class="font-bold">
            Qual è <span class="text-yellow-500">il premio</span> per il mio
            amico?
          </title-two>
          <title-three>
            Una volta attivato il servizio Nibble egli avrà diritto ad 1 mese di
            navigazione gratuito.
          </title-three>

          <title-two class="font-bold">
            <span class="text-yellow-500">Come</span> posso presentare i miei
            amici?
          </title-two>
          <title-three>
            Compila il form di seguito inserendo il tuo codice cliente e i dati
            della persona da contattare che ci vuoi presentare. Riceverai una
            mail di conferma di avvenuta ricezione dell'invito.
          </title-three>
        </div>
        <div
          class="flex-auto pl-4 pb-4 border-b-2 border-gray-300 md:border-none"
        >
          <img src="/assets/img/cashback.svg" class="text-yellow-400" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanModal",
  props: ["data"],
  methods: {
    async onSubmit() {
      console.log("submitted");
      this.formState = "loading";
      setTimeout(() => {
        this.formState = "ending";
      }, 1000);
      setTimeout(() => {
        this.formState = "ready";
      }, 2000);
      await this.$store.dispatch("cashback");
    },
  },
};
</script>
