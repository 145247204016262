<template>
  <div class="relative z-0 overflow-hidden shadow-inner">
    <div class="bg-yellow-400 bg-opacity-80">
      <div class="max-w-7xl mx-auto py-8 sm:py-16 md:py-20">
        <div class="flex flex-col space-y-6 md:space-y-8 mx-4 md:mx-10">
          <title-one>Contattaci</title-one>
          <title-two class="uppercase">
            Segli il modo che preferisci per comunicare con noi</title-two
          >
          <div class="flex flex-row space-x-4 items-start">
            <div class="rounded-full bg-gray-900 text-gray-100 p-2  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <div class="flex flex-col text-gray-900">
              <p class="text-xl uppercase">Indirizzo</p>
              <p class="text-lg">
                Via Manara Valgimigli 74, San Piero in Bagno 47021 (FC)
              </p>
            </div>
          </div>
          <div class="flex flex-row space-x-4 items-start">
            <div class="rounded-full bg-gray-900 text-gray-100 p-2  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
            </div>
            <div class="flex flex-col text-gray-900">
              <p class="text-xl uppercase">Telefono</p>
              <a href="tel:+390543918593" class="text-lg">
                +39 0543 91 85 93
              </a>
            </div>
          </div>
          <div class="flex flex-row space-x-4 items-start">
            <div class="rounded-full bg-gray-900 text-gray-100 p-2  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="flex flex-col text-gray-900">
              <p class="text-xl uppercase">Orari d'ufficio</p>
              <p class="text-lg">
                Lun - Ven <br>
                09:00 - 13:00 <br>
                14:00 - 18:00
              </p>
            </div>
          </div>

          <p class="text-md text-gray-900 pt-10">
            Altrimenti compila il form sottostante ed un nostro incaricato ti
            ricontatterà al più presto.
          </p>

          <transition name="slide-top-blur-2">
            <contact-form v-if="!isLoading"></contact-form>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import docs from "@/json/documents.json";
import ContactForm from "@/components/forms/ContactForm";

export default {
  name: "PrivacyPolicyPage",
  components: { ContactForm },
  data() {
    return {
      isLoading: true,
      docs: docs,
      served_file: "",
    };
  },
  computed: {
    privacy_docs() {
      return this.docs.filter((el) => {
        console.log(el);
        return el.category == "privacy";
      });
    },
  },
  mounted() {
    this.isLoading = !this.isLoading;
  },
  methods: {
    hoverDocument(filepath) {
      console.log("hover");
      this.privacy_docs
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = true;
        });
      return;
    },
    leaveDocument(filepath) {
      this.privacy_docs
        .filter((el) => {
          return el.dst_path == filepath;
        })
        .forEach((element) => {
          element.isHover = false;
        });
      return;
    },
  },
};
</script>

<style scoped>
/* slide top blur effect for blocks */
.slide-top-blur-2-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-3-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.25s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.25s;
}
.slide-top-blur-4-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  -webkit-animation-delay: 0.5s;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
