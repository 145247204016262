<template>
  <div class="hidden md:block relative pt-10 pb-4 px-4 sm:px-6 lg:px-8">
    <nav
      class="relative flex items-center justify-between sm:h-10 lg:justify-start"
      aria-label="Global"
    >
      <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
        <div
          class="flex flex-row items-center justify-between w-full md:w-auto text-xl font-bold text-gray-600 "
        >
          <a href="/">
            <span class="sr-only">Workflow</span>
            <img class="h-8 w-auto sm:h-10" src="/assets/logos/nibble.svg" />
          </a>
          <!-- <p class="text-shadow">nibble</p> -->
        </div>
      </div>
      <div class="md:ml-10 md:pr-4 md:space-x-8">
        <router-link
          to="/privates"
          :class="isDark ? 'text-gray-300' : 'text-gray-600'"
          class="mainButton font-medium"
          >Privati</router-link
        >

        <router-link
          to="/business"
          :class="isDark ? 'text-gray-300' : 'text-gray-600'"
          class="mainButton font-medium"
          >Business</router-link
        >
        
        <router-link
          to="/voip"
          :class="isDark ? 'text-gray-300' : 'text-gray-600'"
          class="mainButton font-medium"
          >Voip</router-link
        >

        <span
          :class="isDark ? 'text-gray-300' : 'text-gray-600'"
          class="relative mainButton py-1 cursor-pointer"
          href="javascript:void(0)"
          @mouseenter="hoverSupportLink"
          @mouseleave="hoverSupportLink"
        >Supporto
          <transition name="slide-top-blur">
            <div
              v-show="isHover"
              class="absolute -right-32 lg:-left-20 top-4 z-50"
              style="width: 600px"
            >
              <div
                :class="isDark ? 'bg-gray-100' : 'bg-gray-900'"
                class="relative mt-10 rounded-lg shadow-2xl"
              >
                <svg
                  :class="isDark ? 'text-gray-100' : 'text-gray-900'"
                  class="block fill-current w-4 h-4 absolute right-0 lg:left-0 top-0 mr-40 lg:ml-28 -mt-3 z-0"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                  ></path>
                </svg>

                <div class="grid grid-cols-2 gap-2 p-7 text-gray-100">
                  <div>
                    <h2 class="text-lg text-gray-400 uppercase pb-6">
                      Supporto
                    </h2>
                    <!-- Assistenza /support/start -->
                    <router-link
                      to="/support/start"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-7 mr-3 text-yellow-500"
                          fill="currentColor"
                        >
                          <path
                            d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Assistenza
                        </p>
                        <p class="text-xs text-gray-500">
                          Ricevi assistenza subito
                        </p>
                      </div>
                    </router-link>

                    <!-- FAQ /support/faq -->
                    <router-link
                      to="/support/faq"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-7 mr-3 text-yellow-500"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          FAQ
                        </p>
                        <p class="text-xs text-gray-500">
                          Domande frequenti
                        </p>
                      </div>
                    </router-link>

                    <!-- Verifica Copertura /support/check-coverage -->
                    <router-link
                      to="/support/check-coverage"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-7 mr-3 text-yellow-500"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Verifica Copertura
                        </p>
                        <p class="text-xs text-gray-500">
                          Inserisci il tuo indirizzo
                        </p>
                      </div>
                    </router-link>

                    <!-- Lavora con noi /support/partnership -->
                    <router-link
                      to="/support/partnership"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-7 mr-3 text-yellow-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                            clip-rule="evenodd"
                          />
                          <path
                            d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Lavora con noi
                        </p>
                        <p class="text-xs text-gray-500">
                          Nibble Partnership Program
                        </p>
                      </div>
                    </router-link>
                  </div>
                  <div>
                    <h2 class="text-lg text-gray-400 uppercase pb-6">
                      Area Legale
                    </h2>

                    <!-- Privacy Policy /legal/privacy-policy -->
                    <router-link
                      to="/legal/"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-7 mr-3 text-yellow-500"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Privacy Policy
                        </p>
                        <p class="text-xs text-gray-500">
                          Gestione e protezione dei dati
                        </p>
                      </div>
                    </router-link>

                    <!-- Trasparenza Tariffaria /legal/tariff-transparency -->
                    <router-link
                      to="/legal/tariff-transparency"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-7 mr-3 text-yellow-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Trasparenza Tariffaria
                        </p>
                        <p class="text-xs text-gray-500">
                          Informazioni per i consumatori
                        </p>
                      </div>
                    </router-link>

                    <!-- Carta dei Servizi /legal/service-charter -->
                    <router-link
                      to="/legal/service-charter"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center mb-6 p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-7 mr-3 text-yellow-500"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Carta dei Servizi
                        </p>
                        <p class="text-xs text-gray-500">
                          Moduli e contratti
                        </p>
                      </div>
                    </router-link>

                    <!-- Contattaci /contact-us -->
                    <router-link
                      to="/legal/contact-us"
                      :class="
                        isDark ? 'hover:bg-gray-200' : 'hover:bg-gray-700'
                      "
                      class="flex flex-row items-center p-1 rounded-lg"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-7 mr-3 text-yellow-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                          />
                        </svg>
                      </div>
                      <div>
                        <p
                          :class="isDark ? 'text-gray-900' : 'text-gray-100'"
                          class="text-xs"
                        >
                          Contattaci
                        </p>
                        <p class="text-xs text-gray-500">
                          Contatti e orari d'apertura
                        </p>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </span>

        <a
          href="https://my.nibble.it"
          class="transition duration-500 ease-in-out inline-flex items-center border border-transparent bg-gradient-to-tr from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 mx-auto px-4 p-1 text-gray-100 rounded-full"
        >
          <double-arrow><div>myNibble</div></double-arrow>
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import DoubleArrow from "./../../ui/buttons/DoubleArrow";
export default {
  name: "DesktopMenu",
  components: { DoubleArrow },
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    hoverSupportLink() {
      this.isHover = !this.isHover;
    },
  },
};
</script>

<style scoped>
a:active,
a.router-link-active {
  @apply text-yellow-500;
}

.mainButton:hover {
  @apply text-yellow-500;
}

.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-leave-active {
  -webkit-animation: slide-top-blur 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both reverse;
  animation: slide-top-blur 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
    reverse;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
