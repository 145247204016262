<template>
    <div class="relative z-0 overflow-hidden shadow-inner">
        <frontoffice-section></frontoffice-section>
    </div>
</template>

<script>
import FrontofficeSection from "./../../sections/support/FrontofficeSection.vue";
export default {
    components: {
        FrontofficeSection,
    },
    name: "SupportPage",
};
</script>