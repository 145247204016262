<template>
  <div id="prices" class="max-w-7xl mx-auto pb-5 sm:pb-10 md:pb-20 ">
    <div class="mx-4">
      <section-heading
        :isDark="isDark"
        :alwaysCentered="true"
        :heading="head.heading"
        :title="head.title"
        :subtitle="head.subtitle"
      ></section-heading>

      <div data-aos="fade-up" data-aos-duration="400">
        <div
          :class="isDark ? 'text-gray-500' : 'text-gray-700'"
          class="flex flex-row justify-center mb-3 text-md md:text-xl tracking-tight font-medium"
        >
          <p class="mx-3">EASY</p>

          <!-- Toggle Button -->
          <label for="toggle" class="flex items-center cursor-pointer">
            <!-- toggle -->
            <div class="relative">
              <!-- hidden input -->
              <input
                id="toggle"
                type="checkbox"
                class="hidden"
                v-model="activationMode"
                true-value="free"
                false-value="easy"
                @click="changeBillingFrequency"
              />
              <!-- line -->
              <div class="w-10 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <!-- dot -->
              <div
                class="toggle_dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0"
              ></div>
            </div>
          </label>

          <p class="mx-3">FREE</p>
        </div>
        <div class="text-gray-400 text-center">
          Seleziona tipologia di attivazione
        </div>
      </div>
      <div class="mb-20 mt-10" data-aos="fade-up" data-aos-duration="400">
        <div
          class="flex flex-col md:flex-row md:transform md:scale-90 lg:scale-100 justify-center w-3/4 md:w-full mx-auto"
        >
          <div
            :class="[
              priceCards.left,
              isDark
                ? 'bg-gray-100 text-gray-900'
                : 'bg-gray-600 text-gray-100',
            ]"
            @mouseover="slideLeft"
            @mouseleave="backRight"
            @click="openPlan(plans.first)"
            class="rounded-lg md:rounded-r-none text-center p-4 sm:p-6 md:p-8 lg:p-10 sm:mx-auto md:mx-0 my-2 md:my-6 font-medium z-10 shadow-lg cursor-pointer w-full lg:w-max"
          >
            <p class="mb-2 font-semibold tracking-wider uppercase">
              {{ activationMode }} {{ plans.first.shortName }}
            </p>
            <div class="grid grid-cols-1 divide-y divide-gray-300">
              <div>
                <div class="font-bold text-5xl sm:text-6xl">
                  &euro; {{ parseInt(plans.first.now / 100)
                  }}<span
                    :class="isDark ? 'text-gray-600' : 'text-gray-400'"
                    class="text-3xl"
                    >.{{ parseInt(plans.first.now % 100) }}</span
                  >
                </div>
                <span>al mese</span>
                <div class="my-1 pb-3 text-base font-bold">
                  <transition name="slideBottom">
                    <p v-if="isFree" class="text-yellow-600">
                      Vincolo 24 mesi
                    </p>
                  </transition>
                  <transition name="slideBottom">
                    <p v-if="isEasy" class="text-green-600">
                      Senza vincoli
                    </p>
                  </transition>
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Download a {{ plans.first.downRate }} Mbps
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Upload a {{ plans.first.upRate }} Mbps
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Tecnologia Fibra Radio FR
                </div>
              </div>
            </div>
            <transition name="fade">
              <div
                v-if="priceCards.hover.left"
                class="absolute inset-x-0 -bottom-0.5 w-max mx-auto"
              >
                <div
                  :class="
                    isDark
                      ? 'bg-gray-900 text-gray-100'
                      : 'bg-gray-100 text-gray-900'
                  "
                  class="rounded-t px-8 py-1"
                >
                  Scopri di più
                </div>
              </div>
            </transition>
          </div>
          <div
            :class="[priceCards.center]"
            @mouseover="slideCenter"
            @mouseleave="backCenter"
            @click="openPlan(plans.second)"
            class="rounded-lg md:rounded-l-none text-center p-4 sm:p-6 md:p-8 lg:p-10 sm:mx-auto md:mx-0 my-2  bg-gradient-to-tr from-yellow-300 to-yellow-500 text-white md:my-6 font-medium z-10 shadow-lg cursor-pointer w-full lg:w-max"
          >
            <p class="mb-2 font-semibold tracking-wider uppercase">
              {{ activationMode }} {{ plans.second.shortName }}
            </p>
            <div class="grid grid-cols-1 divide-y divide-gray-100">
              <div>
                <div class="font-bold text-5xl sm:text-6xl">
                  &euro; {{ parseInt(plans.second.now / 100)
                  }}<span class="text-3xl text-yellow-200"
                    >.{{ parseInt(plans.second.now % 100) }}</span
                  >
                </div>
                <span>al mese</span>
                <div class="my-1 pb-3 text-base font-bold">
                  <transition name="slideBottom">
                    <p v-if="isFree" class="text-yellow-600">
                      Vincolo 24 mesi
                    </p>
                  </transition>
                  <transition name="slideBottom">
                    <p v-if="isEasy" class="text-green-600">
                      Senza vincoli
                    </p>
                  </transition>
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Download a {{ plans.second.downRate }} Mbps
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Upload a {{ plans.second.upRate }} Mbps
                </div>
              </div>
              <div>
                <div class="text-sm my-3">
                  Tecnologia Fibra Radio FR
                </div>
              </div>
            </div>
            <transition name="fade">
              <div
                v-if="priceCards.hover.center"
                class="absolute inset-x-0 -bottom-0.5 w-max mx-auto"
              >
                <div
                  :class="
                    isDark
                      ? 'bg-gray-900 text-gray-100'
                      : 'bg-gray-100 text-gray-900'
                  "
                  class="rounded-t px-8 py-1"
                >
                  Scopri di più
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "PlansSection",
  props: ["isDark", "head", "details"],
  data() {
    return {
      activationMode: "easy",
      priceCards: {
        left: ["transform"],
        center: ["transform"],
        right: ["transform"],
        hover: {
          left: false,
          center: false,
          right: false,
        },
      },
      plans: this.details,
    };
  },
  computed: {
    isFree() {
      return this.activationMode == "easy" ? true : false;
    },
    isEasy() {
      return this.activationMode == "free" ? true : false;
    },
  },
  methods: {
    slideLeft() {
      this.priceCards.left = [
        "transform",
        "sm:scale-95",
        "sm:px-32 pb-10 sm:pb-2",
        "duration-200",
      ];
      this.priceCards.hover.left = true;
    },
    // slideRight() {
    //   this.priceCards.right = [
    //     "transform",
    //     "sm:scale-95",
    //     "sm:px-32 pb-10 sm:pb-2",
    //     "duration-200",
    //   ];
    //   this.priceCards.hover.right = true;
    // },
    slideCenter() {
      this.priceCards.center = [
        "transform",
        "sm:scale-95",
        "sm:px-32 pb-10 sm:pb-2",
        "duration-200",
      ];
      this.priceCards.hover.center = true;
    },
    backRight() {
      this.priceCards.left = ["transform", "duration-200"];
      this.priceCards.hover.left = false;
    },
    backLeft() {
      this.priceCards.right = ["transform", "duration-200"];
      this.priceCards.hover.right = false;
    },
    backCenter() {
      this.priceCards.center = ["transform", "duration-200"];
      this.priceCards.hover.center = false;
    },
    openPlan(data) {
      setTimeout(() => {
        this.$store.dispatch("setModal", {
          newstate: true,
          page: "privates-plan",
          data: {
            model: data,
            type: "privates",
            activationMode: this.activationMode,
            planname: data.name,
            downloadRate: data.downRate,
            uploadRate: data.upRate,
            price: data.price,
          },
        });
      }, 200);
    },
    changeBillingFrequency() {
      this.incrementalPricing(
        "first",
        this.plans.first.start,
        this.plans.first.end
      );
      this.incrementalPricing(
        "second",
        this.plans.second.start,
        this.plans.second.end
      );
    },
    incrementalPricing(plan, start, end) {
      if (this.activationMode == "easy") {
        var up = setInterval(() => {
          this.plans[plan].now += 5;
          if (this.plans[plan].now + 5 > end) {
            this.plans[plan].now = end;
            clearInterval(up);
          }
        }, 5);
      } else {
        var down = setInterval(() => {
          this.plans[plan].now -= 5;
          if (this.plans[plan].now - 5 < start) {
            this.plans[plan].now = start;
            clearInterval(down);
          }
        }, 5);
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 640px) {
  .sm\:bg-svg-bottom {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='353' height='304'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23D0D5F6' stroke-width='2'%3E%3Cpath d='M180.29 759c102.087-21.62 155.232-61.312 159.437-119.074 6.307-86.643-231.598-17.186-136.358-198 95.241-180.813 181.318-185.29 136.358-298C294.767 31.216 174.04-27.954 33.79 16.8c-93.501 29.836-144.652 140.545-153.453 332.126'/%3E%3Cpath d='M138.3 759c80.083-18.988 121.774-53.846 125.072-104.575 4.948-76.093-181.679-15.094-106.966-173.89C231.118 321.738 298.64 317.808 263.372 218.82c-35.269-98.986-129.974-150.95-239.995-111.646C-49.97 133.378-90.096 230.605-97 398.859'/%3E%3Cpath d='M102.065 761c60.604-16.56 92.153-46.963 94.65-91.208 3.743-66.367-137.488-13.165-80.949-151.664 56.54-138.5 107.638-141.927 80.948-228.261-26.69-86.335-98.359-131.656-181.618-97.376C-40.41 215.345-70.775 300.145-76 446.892'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.toggle_dot {
  top: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle_dot {
  transform: translateX(100%);
  background-color: #0082c8;
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s ease-in;
}

.fade-enter-to .fade-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
</style>
