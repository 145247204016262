<template>
  <div class="bg-gray-900 text-gray-300">
    <div class="max-w-7xl mx-auto py-5 sm:py-10 md:pt-40 md:pb-20">
      <div class="mx-4 md:mx-6 mb-20 sm:mb-0 mt-2 sm:mt-0 grid grid-cols-12">
        <div class="col-span-12 md:col-span-3">
          <div
            class="flex flex-col sm:flex-row md:flex-col sm:space-x-4 md:space-x-0 items-center sm:items-start content-center md:content-between pt-2 pb-6 md:pb-0"
          >
            <div class="flex flex-col items-center sm:items-start">
              <div class="mx-auto sm:mx-0">
                <a href="#">
                  <span class="sr-only">Workflow</span>
                  <img
                    class="h-6 sm:h-10 md:h-12 w-auto mr-2 my-1"
                    src="/assets/logos/nibble.svg"
                  />
                </a>
              </div>
              <div class="md:mb-4 mt-3 md:mt-5 mx-auto sm:mx-0">
                <span class="text-base font-semibold text-yellow-500">
                  Nibble Network.
                </span>
                Stay Connected.
              </div>
            </div>
            <div
              class="flex-grow flex flex-row justify-evenly sm:justify-end space-x-4 md:space-x-2 lg:space-x-4 sm:px-2 md:px-0 lg:pr-14 pt-6 pb-2 sm:pt-1"
            >
              <a
                data-aos="fade-right"
                data-aos-delay="0"
                data-aos-duration="400"
                href="https://www.facebook.com/nibblenetwork/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="pr-2 h-6 sm:h-10 md:h-6 lg:h-6 xl:h-8 w-auto"
                  src="/assets/img/facebook.png"
                />
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="50"
                data-aos-duration="400"
                href="https://www.instagram.com/nibblenetwork/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="pr-2 h-6 sm:h-10 md:h-6 lg:h-6 xl:h-8 w-auto"
                  src="/assets/img/instagram.png"
                />
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="400"
                href="https://it.linkedin.com/company/nibblesrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="pr-2 h-6 sm:h-10 md:h-6 lg:h-6 xl:h-8 w-auto"
                  src="/assets/img/linkedin.png"
                />
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="150"
                data-aos-duration="400"
                href="http://m.me/nibblenetwork"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="pr-2 h-6 sm:h-10 md:h-6 lg:h-6 xl:h-8 w-auto"
                  src="/assets/img/messenger.png"
                />
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="400"
                href="https://my.nibble.it/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="pr-2 h-6 sm:h-10 md:h-6 lg:h-6 xl:h-8 w-auto"
                  src="/assets/img/lock-in-a-circle.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-span-12 sm:col-span-4 md:col-span-3 border-solid border-t border-gray-500 sm:border-none"
        >
          <div @click="footerCol1Change" class="flex justify-between">
            <h2
              class="text-base sm:text-base sm:font-semibold tracking-wide py-2 sm:px-4"
            >
              Soluzioni
            </h2>
            <h2 class="sm:hidden text-md tracking-wide p-2">
              +
            </h2>
          </div>
          <collapse-transition :duration="400"
            ><ul class="p-3 sm:hidden" v-show="footerCol1">
              <router-link to="/privates">
                <li class="pb-3 text-sm">Privati</li>
              </router-link>
              <router-link to="/business">
                <li class="pb-3 text-sm">Business</li>
              </router-link>
              <router-link to="/voip">
                <li class="pb-3 text-sm">Voip</li>
              </router-link>
              <router-link to="/about">
                <li class="pb-3 text-sm">Chi siamo</li>
              </router-link>
            </ul></collapse-transition
          >
          <ul class="hidden sm:block py-2 px-4">
            <router-link to="/privates">
              <li class="pb-3 text-sm">Privati</li>
            </router-link>
            <router-link to="/business">
              <li class="pb-3 text-sm">Business</li>
            </router-link>
            <router-link to="/voip">
              <li class="pb-3 text-sm">Voip</li>
            </router-link>
            <router-link to="/about">
              <li class="pb-3 text-sm">Chi siamo</li>
            </router-link>
          </ul>
        </div>

        <div
          class="col-span-12 sm:col-span-4 md:col-span-3 border-solid border-t border-gray-500 sm:border-none"
        >
          <div @click="footerCol2Change" class="flex justify-between">
            <h2
              class="text-base sm:text-base sm:font-semibold tracking-wide py-2 sm:px-4"
            >
              Supporto
            </h2>
            <h2 class="sm:hidden text-md tracking-wide p-2">
              +
            </h2>
          </div>
          <collapse-transition :duration="400"
            ><ul class="p-3 sm:hidden" v-show="footerCol2">
              <router-link to="/support/start">
                <li class="pb-3 text-sm">Assistenza</li>
              </router-link>
              <router-link to="/support/faq">
                <li class="pb-3 text-sm">FAQ</li>
              </router-link>
              <router-link to="/support/check-coverage">
                <li class="pb-3 text-sm">Verifica copertura</li>
              </router-link>
              <router-link to="/support/partnership">
                <li class="pb-3 text-sm">Lavora con noi</li>
              </router-link>
            </ul></collapse-transition
          >
          <ul class="hidden sm:block py-2 px-4">
            <router-link to="/support/start">
              <li class="pb-3 text-sm">Assistenza</li>
            </router-link>
            <router-link to="/support/faq">
              <li class="pb-3 text-sm">FAQ</li>
            </router-link>
            <router-link to="/support/check-coverage">
              <li class="pb-3 text-sm">Verifica copertura</li>
            </router-link>
            <router-link to="/support/partnership">
              <li class="pb-3 text-sm">Lavora con noi</li>
            </router-link>
          </ul>
        </div>

        <div
          class="col-span-12 sm:col-span-4 md:col-span-3 border-solid border-t border-gray-500 sm:border-none"
        >
          <div @click="footerCol3Change" class="flex justify-between">
            <h2
              class="text-base sm:text-base sm:font-semibold tracking-wide py-2 sm:px-4"
            >
              Area Legale
            </h2>
            <h2 class="sm:hidden text-md tracking-wide p-2">
              +
            </h2>
          </div>
          <collapse-transition :duration="400"
            ><ul class="p-3 sm:hidden" v-show="footerCol3">
              <router-link to="/legal/privacy-policy">
                <li class="pb-3 text-sm">Privacy Policy</li>
              </router-link>
              <router-link to="/legal/tariff-transparency">
                <li class="pb-3 text-sm">Trasparenza tariffaria</li>
              </router-link>
              <router-link to="/legal/service-charter">
                <li class="pb-3 text-sm">Carta dei servizi</li>
              </router-link>
              <router-link to="/legal/contact-us">
                <li class="pb-3 text-sm">Contattaci</li>
              </router-link>
            </ul></collapse-transition
          >
          <ul class="hidden sm:block py-2 px-4">
            <router-link to="/legal/privacy-policy">
              <li class="pb-3 text-sm">Privacy Policy</li>
            </router-link>
            <router-link to="/legal/tariff-transparency">
              <li class="pb-3 text-sm">Trasparenza tariffaria</li>
            </router-link>
            <router-link to="/legal/service-charter">
              <li class="pb-3 text-sm">Carta dei servizi</li>
            </router-link>
            <router-link to="/legal/contact-us">
              <li class="pb-3 text-sm">Contattaci</li>
            </router-link>
          </ul>
        </div>
        <div class="col-span-12 border-solid border-t border-gray-500 pt-8">
          <div class="grid sm:grid-cols-2">
            <div class="text-center sm:text-left">
              <p class="text-sm text-gray-500">
                Copyright © {{ year }} Nibble S.r.l. <br />
                Tutti i diritti riservati.
              </p>
            </div>
            <div
              class="text-sm text-gray-500 text-center sm:text-right hover:underline pt-8 sm:pt-0"
            >
              <a href="https://www.linkedin.com/in/frasagal/" target="_blank">
                Made with ♥ by FraSaGal
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import moment from "moment";

export default {
  name: "TheFooter",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      footerCol1: false,
      footerCol2: false,
      footerCol3: false,
    };
  },
  computed: {
    year() {
      return moment().year();
    },
  },
  methods: {
    footerCol1Change() {
      this.footerCol1 = !this.footerCol1;
    },
    footerCol2Change() {
      this.footerCol2 = !this.footerCol2;
    },
    footerCol3Change() {
      this.footerCol3 = !this.footerCol3;
    },
    reset() {
      this.footerCol1 = false;
      this.footerCol2 = false;
      this.footerCol3 = false;
    },
  },
};
</script>

<style scoped>
ul {
  cursor: pointer;
}
</style>
