<template>
  <main
    class="mt-10 mx-auto max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 2xl:mt-28 z-50"
  >
    <div class="sm:text-center lg:text-left">
      <div
        :class="data.isDark ? 'text-gray-100' : 'text-gray-900'"
        class="text-5xl tracking-tight font-extrabold lg:text-6xl 2xl:text-7xl"
      >
        <p
          data-aos="fade-down"
          data-aos-duration="400"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          class="block pb-4 font-normal text-lg sm:text-xl tracking-normal text-gray-500"
          v-html="data.header"
        ></p>
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="400"
          data-aos-delay="150"
        >
          <span v-html="data.title"> </span>
          <span v-html="data.highlightTitle" class="text-yellow-500 mr-3">
          </span>
        </div>
      </div>
      <p
        data-aos="fade-right"
        data-aos-duration="600"
        data-aos-offset="0"
        data-aos-easing="ease-in-sine"
        :class="data.isDark ? 'text-gray-400' : 'text-gray-500'"
        v-html="data.description"
        class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-10 md:text-xl 2xl:text-2xl lg:mx-0 transition duration-500 ease-in-out"
      ></p>

      <!-- buttons -->
      <div
        v-show="
          (buttonA && buttonA.mode == true) || (buttonB && buttonB.mode == true)
        "
        class="mt-5 sm:mt-10 lg:mt-8 xl:mt-14 sm:flex sm:justify-center lg:justify-start"
      >
        <div
          data-aos="fade-up"
          data-aos-duration="400"
          data-aos-easing="ease-in-sine"
          data-aos-offset="0"
          v-if="data.buttonA.mode"
          class="rounded-md shadow-inner"
        >
          <a
            :href="data.buttonA.link"
            :class="data.isDark ? 'text-gray-900' : 'text-gray-100'"
            class="w-full flex items-center justify-center px-8 py-3 font-medium rounded-md bg-gradient-to-tr from-yellow-300 to-yellow-500 hover:from-yellow-400 hover:to-yellow-600 md:py-4 md:text-lg md:px-10"
          >
            {{ data.buttonA.text }}
          </a>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="400"
          data-aos-easing="ease-in-sine"
          data-aos-offset="0"
          v-if="data.buttonB.mode"
          class="mt-3 sm:mt-0 sm:ml-3"
        >
          <a
            :href="data.buttonB.link"
            class="w-full flex items-center justify-center px-8 py-3 border border-transparent font-medium rounded-md text-yellow-500 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
          >
            {{ data.buttonB.text }}
          </a>
        </div>
      </div>
      <slot></slot>
    </div>
  </main>
</template>

<script>
export default {
  name: "ExtendedHeader",
  props: {
    data: {
      type: Object,
      default() {
        return {
          isDark: false,
          header: "",
          title: "",
          highlightTitle: "",
          description: "",
          buttonA: {
            type: Object,
            default: () => ({
              mode: false,
            }),
          },
          buttonB: {
            type: Object,
            default: () => ({
              mode: false,
            }),
          },
        };
      },
    },
  },
  methods: {
    openModal() {
      setTimeout(() => {
        this.$store.dispatch("setModal", {
          newstate: true,
          page: "coverage",
        });
      }, 200);
    },
  },
};
</script>
