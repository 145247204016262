<template>
    <transition name="route">
        <the-modal v-show="modal"> </the-modal>
    </transition>
    <router-view name="header"></router-view>
    <main>
        <transition name="route" mode="out-in">
            <router-view name="default" v-slot="slotProps">
                <component :is="slotProps.Component"></component>
            </router-view>
        </transition>
    </main>
    <router-view name="footer"></router-view>
</template>

<script>
import TheModal from "./layout/TheModal";

export default {
    name: "App",
    components: {
        TheModal,
    },
    computed: {
        modal() {
            return this.$store.getters["modalStatus"];
        },
    },
    watch: {
        modal: function () {
            if (this.modal) {
                document.documentElement.style.overflow = "hidden";
                return;
            }

            document.documentElement.style.overflow = "auto";
        },
    },
};
</script>

<style>
.route-enter-from {
    opacity: 0;
}

.route-leave-to {
    opacity: 0;
}

.route-enter-active {
    transition: all 0.3s ease-out;
}

.route-leave-active {
    transition: all 0.3s ease-in;
}

.route-enter-to .route-leave-from {
    opacity: 1;
}

.slideBottom-enter-from {
    transform: translateY(20%);
}

.slideBottom-leave-to {
    transform: translateY(20%);
}

.slideBottom-enter-active {
    transition: all 0.3s ease-out;
}

.slideBottom-leave-active {
    transition: all 0.3s ease-in;
}

.slideBottom-enter-to .slideBottom-leave-from {
    transform: translateY(100%);
}
</style>
